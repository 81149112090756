const questions = [
  {
    id: 1,
    question:
      "<span class='bold'>요즘들어 살인이나 상해 사건이 도처에서 횡횡합니다.</span> 병원은 마비되고, 좋지 않은 뉴스만 계속 들려옵니다.<br>" +
      "을씨년스러운 시내 한복판에서 걸음을 재촉하던 당신의 주변에 돌연, 사이렌 소리와 비명소리가 울려 퍼집니다." +
      "<br>라디오와 각종 매체에서 <span class='bold'>'긴급 재난 방송'</span>이라는 문구가 반복 송출됩니다.<br>당황과 공포로 어수선한 대로에서, 사람들은 서둘러 집으로 돌아가기 위해 걸음을 재촉합니다.<br>그 순간, 당신 앞의 누군가가 비명을 지릅니다. <span class='bold'>사람을 뜯어 먹는 무리들이</span> 시내의 병원에서 우르르 뛰쳐나와 시민들을 공격하기 시작합니다.<br><br><span class='bold'>당신은 이 혼란 속에서 제일 먼저 어떻게 행동하나요?</span>",
    options: [
      {
        answer: "근처 상점으로 뛰어들어 문을 잠그고 숨는다.",
        type: ["I", "S", "P"],
        weight: [2, 2, 3],
        survivalRate: 3,
        riskTaking: -2,
        morality: 0,
        situationalAwareness: 4,
        leadership: -2,
        decisiveness: 3,
        resilience: 1,
      },
      {
        answer: "가까운 물건을 들고 좀비로부터 자신을 방어할 준비를 한다.",
        type: ["S", "T", "P"],
        weight: [3, 1, 1],
        survivalRate: 2,
        riskTaking: 3,
        morality: 0,
        situationalAwareness: 3,
        leadership: 2,
        decisiveness: 3,
        resilience: 3,
      },
      {
        answer:
          "비명을 지르는 사람들에게 달려가 그들을 돕고 안전한 곳으로 인도한다.",
        type: ["E", "F", "N"],
        weight: [2, 3, 1],
        survivalRate: -3,
        riskTaking: 4,
        morality: 3,
        situationalAwareness: -1,
        leadership: 4,
        decisiveness: 0,
        resilience: 3,
      },
      {
        answer: "가족이나 친구에게 전화해서 상황을 알린다.",
        type: ["F", "N", "I", "P"],
        weight: [1, 1, 1, 1],
        survivalRate: -1,
        riskTaking: 2,
        morality: 1,
        situationalAwareness: 1,
        leadership: 2,
        decisiveness: -2,
        resilience: -1,
      },
    ],
  },
  {
    id: 2,
    question:
      "<span class='bold'>도시는 아비규환입니다. 당신은 가까스로 건물 안으로 몸을 피했습니다.</span> 건물 안에는 이미 도망쳐 온 생존자 몇 명이 모여 숨어 있습니다.<br>그들은 불안한 눈빛으로 당신에게 부탁합니다.<br>" +
      "<span class='quote mt10'>'저기요.'</span>" +
      "<span class='quote mb10'>'혹시 의료품이 있다면 좀 나눠주거나 구해다 줄 수 있어요? 친구가 많이 다쳤어요.'</span>" +
      "이 건물에 몸을 의탁하려면 <span class='bold'>아무래도 그들의 호감을 살 필요가 있어 보입니다.</span> 어떻게 할까요?",
    options: [
      {
        answer: "그들의 신뢰를 얻기 위해 의료품을 찾아오겠다고 제안한다.",
        type: ["E", "T", "J", "N"],
        weight: [3, 1, 2, 1],
        survivalRate: 2,
        riskTaking: 3,
        morality: 0,
        situationalAwareness: 3,
        leadership: 2,
        decisiveness: 3,
        resilience: 2,
      },
      {
        answer:
          "도움을 못 줘서 미안하지만, 혹시 들여보내줄 수 없느냐고 간곡히 부탁한다.",
        type: ["F", "I", "S", "P"],
        weight: [2, 1, 1, 1],
        survivalRate: 0,
        riskTaking: -3,
        morality: 0,
        situationalAwareness: -3,
        leadership: 0,
        decisiveness: -2,
        resilience: -1,
      },
      {
        answer:
          "지금은 무슨 수를 써서든 여기 머물러야 한다. 위협은 진입 후에 해도 늦지 않으므로, 의료품이 있다고 거짓말을 한다.",
        type: ["F", "N", "J", "E"],
        weight: [2, 1, 2, 1],
        survivalRate: 1,
        riskTaking: 1,
        morality: -2,
        situationalAwareness: 1,
        leadership: -1,
        decisiveness: 2,
        resilience: 2,
      },
      {
        answer:
          "일 초가 아까운 상황인데 여기서 실랑이하는 건 시간 낭비다. 그냥 떠난다.",
        type: ["I", "P", "S", "T"],
        weight: [2, 3, 1, 1],
        survivalRate: 2,
        riskTaking: -3,
        morality: 0,
        situationalAwareness: 0,
        leadership: 0,
        decisiveness: 4,
        resilience: 1,
      },
    ],
  },

  {
    id: 3,
    question:
      "<span class='bold'>우여곡절 끝에 결국 당신은 그 건물에 머무르기로 합니다.</span>" +
      "<br>바깥에서는 변이한 사람들을 '좀비'라고 부르는 모양입니다. 당신은 라디오에서 이와 같은 증상이 전염성이 있다는 사실을 듣게 됩니다." +
      "<br>얼마 가지 않아 당신은 <span class='bold'>이 건물의 부상자가 점점 바깥의 사람들과 비슷하게 변하고 있다는 사실을 알아차립니다.</span><br>그의 상처에 <i>그것들</i>의 이빨 자국이 선명히 남아 있습니다.<br>" +
      "환자의 열이 오르락내리락 합니다. 그는 이따금 열에 들떠 헛소리를 하며, 불안한 눈빛으로 연신 주변을 두리번거립니다. 자신의 상태를 숨기려는 듯 보입니다.<br>" +
      "<br><span class='bold'>당신은 어떻게 할까요?</span>",
    options: [
      {
        answer:
          "즉시 그를 감염자라고 밝히고, 다른 생존자들에게 필요하다면 제거해야 할 것이라고 경고한다.",
        type: ["T", "P", "E", "S"],
        weight: [1, 1, 1, 1],
        survivalRate: 3,
        riskTaking: -3,
        morality: 0,
        situationalAwareness: 3,
        leadership: 3,
        decisiveness: 3,
        resilience: -3,
      },
      {
        answer:
          "아직 확실하지 않으니, 조용히 상대를 도우며 감염 여부를 계속 관찰한다.",
        type: ["F", "N", "I", "P"],
        weight: [3, 1, 2, 1],
        survivalRate: -1,
        riskTaking: 3,
        morality: 3,
        situationalAwareness: -2,
        leadership: 0,
        decisiveness: -2,
        resilience: 0,
      },
      {
        answer:
          "상황이 위험하니 자신의 안전을 최우선으로 한다. 괜히 분란 일으키는 대신 그 무리를 떠난다.",
        type: ["I", "S", "T", "P"],
        weight: [2, 2, 2, 1],
        survivalRate: 3,
        riskTaking: -3,
        morality: 0,
        situationalAwareness: -3,
        leadership: 0,
        decisiveness: 3,
        resilience: -2,
      },
      {
        answer:
          "감염에 대해 관찰할 좋은 기회다. 어차피 곧 사람이 아니게 될 테니 나를 비난할 사람은 없을 것이다.",
        type: ["T", "P", "N", "E"],
        weight: [1, 2, 3, 1],
        survivalRate: -1,
        riskTaking: 3,
        morality: -2,
        situationalAwareness: 3,
        leadership: -2,
        decisiveness: 2,
        resilience: 2,
      },
      {
        answer:
          "무작정 죽이고 싶지는 않다. 다른 생존자들과 의논하고, 어떻게 할지 결정을 함께 내린다.",
        type: ["E", "F", "J", "S"],
        weight: [3, 2, 2, 1],
        survivalRate: 0,
        riskTaking: 0,
        morality: 2,
        situationalAwareness: 0,
        leadership: -2,
        decisiveness: -4,
        resilience: 1,
      },
    ],
  },

  {
    id: 4,
    question:
      "<span class='bold'>그리고 나서 또 며칠. 당신은 며칠 동안 은신처에 머물며 가까스로 목숨을 부지하고 있습니다.</span><br>" +
      "시간이 지날수록 식량이 점점 고갈되어 갑니다. 당신과 은신처의 사람들은 얼마 전부터 하루에 한 끼도 제대로 먹지 못했습니다.<br><span class='bold'>굶주림이 한계에 이르렀습니다. </span> 썩어가는 시체를 먹을 순 없잖아요.<br>" +
      "당신에겐 얼마 전 위험을 무릅쓰고 편의점에서 털어온 먹을거리들이 배낭에 남아 있지만, 다른 사람들은 이를 모릅니다.<br>" +
      "<br><span class='bold'>이 식량을 어떻게 처리할까요?</span>",
    options: [
      {
        answer:
          "많은 사람들이 몸을 보전하는 게 중요하다. 식량을 나눠 모두가 함께 먹을 수 있게 한다.",
        type: ["F", "E", "N", "P"],
        weight: [3, 1, 2, 1],
        survivalRate: 1,
        riskTaking: 3,
        morality: 3,
        situationalAwareness: 1,
        leadership: 2,
        decisiveness: 2,
        resilience: 2,
      },
      {
        answer:
          "어쩌면 내게 도움이 될 것 같은 사람 한둘 정도는 이 식량으로 포섭할 수 있을 것이다.",
        type: ["T", "J", "N", "E"],
        weight: [2, 3, 2, 1],
        survivalRate: 3,
        riskTaking: 2,
        morality: 0,
        situationalAwareness: 3,
        leadership: 1,
        decisiveness: 3,
        resilience: 0,
      },
      {
        answer:
          "상황을 어느 정도 지켜본다. 꼭 필요한 사람, 혹은 친밀한 사람에게만 나누어 준다.",
        type: ["N", "P", "I", "T"],
        weight: [1, 2, 2, 1],
        survivalRate: 1,
        riskTaking: 1,
        morality: 1,
        situationalAwareness: 1,
        leadership: 0,
        decisiveness: -1,
        resilience: 2,
      },
      {
        answer:
          "이건 내 거고, 나눌 이유가 없다. 나를 챙긴다. 다른 사람들에게는 주지 않을 것이다.",
        type: ["S", "T", "I", "P"],
        weight: [1, 2, 2, 1],
        survivalRate: 3,
        riskTaking: -3,
        morality: -1,
        situationalAwareness: 2,
        leadership: -2,
        decisiveness: 2,
        resilience: -1,
      },
    ],
  },
  {
    id: 5,
    question:
      "<span class='bold'>당신은 탐색을 위해 폐허가 된 건물 주변을 살피다 또 다른 생존자 무리를 발견합니다.</span><br>" +
      "그들은 피폐한 얼굴과 초점 없는 눈빛으로 당신을 둘러싸기 시작합니다.<br>한 사람이 무리에서 나와 다가오며, 날카로운 눈빛으로 당신을 위아래로 훑습니다.<br>" +
      "<span class='quote mb10 mt10'>'너희도 여기서 죽고 싶진 않겠지?'</span> 그가 말합니다. 주변의 다른 생존자들이 그의 옆에 서서 당신의 소지품을 연신 훑어봅니다.<br>" +
      "<span class='quote mb10 mt10'>'배낭과 짐을 여기에 전부 두고 가. 그럼 목숨은 부지하게 해 주지.'</span>" +
      "분위기가 점점 험악해집니다. 당신이 혹여 싸움을 꺼리지 않는 사람이라고 하더라도, 상황은 별로 좋지 않습니다...<br>그들에게 총알이 얼마나 있는지는 모릅니다. 다만 무장을 하고 있다는 사실은 확실합니다.<br>당신은 피로한 상태이며, 무엇보다 지금은 혼자입니다. 싸움을 벌인다면 승리를 장담할 수 없습니다.",
    options: [
      {
        answer:
          "지금 싸우는 건 무리다. 배낭을 넘기자. 무사히 빠져나오는 게 최우선이다.",
        type: ["T", "J", "N", "I"],
        weight: [2, 2, 2, 1],
        survivalRate: 3,
        riskTaking: -3,
        morality: 0,
        situationalAwareness: 3,
        leadership: 1,
        decisiveness: 1,
        resilience: -2,
      },
      {
        answer: "협조하는 척, 도망갈 기회를 엿보다가 어떻게든 빠르게 도망친다.",
        type: ["S", "P", "I", "F"],
        weight: [2, 2, 1, 1],
        survivalRate: -1,
        riskTaking: 3,
        morality: 0,
        situationalAwareness: 1,
        leadership: 0,
        decisiveness: 2,
        resilience: -2,
      },
      {
        answer:
          "협상을 해 본다. 어쩌면 피를 보지 않고 해결할 수 있을지도 모른다.",
        type: ["T", "N", "J", "E"],
        weight: [2, 3, 2, 1],
        survivalRate: 1,
        riskTaking: 2,
        morality: 1,
        situationalAwareness: 1,
        leadership: 3,
        decisiveness: 1,
        resilience: 2,
      },
      {
        answer:
          "내 배낭에 뭐가 들어 있는 줄 알고 저러지? 본보기로 먼저 몇 놈 슥삭한다.",
        type: ["F", "S", "P", "E"],
        weight: [2, 1, 2, 1],
        survivalRate: -2,
        riskTaking: 3,
        morality: -4,
        situationalAwareness: -3,
        leadership: 0,
        decisiveness: 3,
        resilience: 3,
      },
      {
        answer:
          "이러나 저러나 위험한 건 마찬가지다. 빼앗기지 않기 위해 그들에게 맞서 싸운다.",
        type: ["F", "S", "E", "J"],
        weight: [1, 1, 1, 1],
        survivalRate: -1,
        riskTaking: 4,
        morality: 1,
        situationalAwareness: 0,
        leadership: 2,
        decisiveness: 4,
        resilience: 2,
      },
    ],
  },

  {
    id: 6,
    question:
      "<span class='bold'>당신은 약탈자로부터 도주하다 무리와 뿔뿔히 흩어져 혼자 은신처로 복귀하고 있습니다.</span><br>무너진 건물 앞을 지나가던 당신은, 그 잔해에 반 정도 파묻힌 생존자를 발견합니다.<br>좀비에게 물린 것 같진 않지만, 그대로 놔둔다면 아마 죽음은 시간 문제일 겁니다.<br>" +
      "<span class='quote mb10 mt10'>'제발, 저 좀 도와주세요. 저 혼자선 못 가요.'</span><span class='quote mb10 mt10'>'저는 의사예요, 분명 도움이 될 거예요.'</span>" +
      "피곤에 지친 목소리와 눈에는 두려움이 가득합니다. 그를 깔고 있는 잔해 밖으로 남자의 배낭이 널부러져 있는 것이 보입니다.<br>당신은 발을 멈춥니다. 정말로 의사라면 분명 도움이 될테지만, 당신은 쫓기고 있는 상황입니다.<br>곧 해가 지고, 어두워지기 전 안전한 곳으로 가지 않으면 당신도 위험에 빠질 수 있습니다.",
    options: [
      {
        answer:
          "부상자를 돕는 건 인간이라면 으레 가져야 할 도리다. 그를 구조한다.",
        type: ["F", "N", "I", "P"],
        weight: [3, 1, 2, 2],
        survivalRate: -1,
        riskTaking: 2,
        morality: 4,
        situationalAwareness: -1,
        leadership: 2,
        decisiveness: 3,
        resilience: 2,
      },
      {
        answer:
          "그가 의사라면 분명 쓸모가 많을 것이다. 일단 그를 믿고 구조한다.",
        type: ["T", "N", "J", "I"],
        weight: [2, 3, 2, 1],
        survivalRate: 2,
        riskTaking: 3,
        morality: 1,
        situationalAwareness: 2,
        leadership: 3,
        decisiveness: 2,
        resilience: 1,
      },
      {
        answer:
          "지금은 위험을 감수하기 어려운 상황이고, 의사라는 말도 신뢰할 수 없다. 그를 두고 떠난다.",
        type: ["I", "T", "S", "J"],
        weight: [2, 3, 1, 1],
        survivalRate: 3,
        riskTaking: -3,
        morality: -1,
        situationalAwareness: 3,
        leadership: 0,
        decisiveness: 2,
        resilience: -1,
      },
      {
        answer: "이게 웬 횡재람. 그의 배낭을 뒤져 필요한 물품을 들고 떠난다.",
        type: ["S", "T", "P", "E"],
        weight: [2, 1, 3, 1],
        survivalRate: 2,
        riskTaking: 0,
        morality: -3,
        situationalAwareness: -1,
        leadership: 0,
        decisiveness: 2,
        resilience: 1,
      },
    ],
  },

  {
    id: 7,
    question:
      "<span class='bold'>당신은 피난처로 향하는 길에, 길 잃은 듯한 떠돌이 강아지를 발견합니다.</span><br>" +
      "강아지는 지쳐 보이고, 가까이 다가올 때마다 꼬리를 흔들며 눈을 맞춥니다. 당신은 <span class='bold'>자원을 아껴야 하는 상황</span>에 처해 있지만, 이 강아지가 당신의 여행에 도움이 될 수도 있습니다.<br>" +
      "다만, 강아지를 데리고 다니면 더 많은 음식이 필요하고, 예상치 못한 위험이 따를 게 분명합니다.<br><br>",
    options: [
      {
        answer:
          "어쩌겠어, 데려가야지. 얘가 지금 나를 보고 꼬리를 흔들었잖아...",
        type: ["F", "N", "P", "E"],
        weight: [3, 1, 2, 1],
        survivalRate: -2,
        riskTaking: 2,
        morality: 2,
        situationalAwareness: -2,
        leadership: 0,
        decisiveness: -2,
        resilience: 3,
      },
      {
        answer: "지금은 내 몸 지키기도 버겁다. 얼른 자리를 뜬다.",
        type: ["T", "N", "J", "I"],
        weight: [3, 1, 1, 1],
        survivalRate: 3,
        riskTaking: -2,
        morality: 0,
        situationalAwareness: 3,
        leadership: 0,
        decisiveness: 1,
        resilience: -2,
      },
      {
        answer: "일단 데려가서 팀원들과 상의를 해봐야겠다.",
        type: ["E", "T", "P", "N"],
        weight: [2, 1, 2, 1],
        survivalRate: 0,
        riskTaking: 0,
        morality: 2,
        situationalAwareness: 1,
        leadership: 2,
        decisiveness: -1,
        resilience: 2,
      },
      {
        answer:
          "안쓰럽지만, 먹을 걸 좀 나눠주고 보내준다. 함께 다니면 더 위험할 수 있다.",
        type: ["F", "I", "S", "J"],
        weight: [1, 2, 2, 1],
        survivalRate: 2,
        riskTaking: -2,
        morality: 2,
        situationalAwareness: 3,
        leadership: 1,
        decisiveness: 3,
        resilience: 1,
      },
    ],
  },

  {
    id: 8,
    question:
      "<span class='bold'>당신과 팀원들은 은신처를 변경하기 위해 조용히 이동하던 중, 허름한 콘크리트 건물에 잠시 몸을 숨겼습니다.</span><br>" +
      "지나가는 도시는 여전히 좀비들로 가득하고, 모두가 긴장 속에서 지쳐 있습니다. 빈 건물에서 잠시 숨을 돌리던 그때, 한 팀원이 실수를 저지릅니다.<br>" +
      "그가 창문을 막으려다 <span class='bold'>무거운 철제 장식장을 쓰러트립니다.</span> 굉음과도 같은 큰 소리가 콘크리트 건물 내부에 울립니다.<br><br>" +
      "불행히도, <span class='bold'>이 소리는 건물 안에서 눈을 붙이던 사람들뿐만 아니라, 밖을 배회하던 것들도 깨워버리고 말았습니다.</span><br>" +
      "이곳은 더이상 안전하지 않습니다. 건물은 오래되었고 출구는 하나뿐이며, 포위되는 건 시간 문제입니다.",
    options: [
      {
        answer:
          "침착하지 않으면 다 죽는다. 일단 모두를 진정시키는 게 우선이다.",
        type: ["F", "N", "E", "J"],
        weight: [2, 2, 1, 1],
        survivalRate: 2,
        riskTaking: 0,
        morality: 1,
        situationalAwareness: 3,
        leadership: 3,
        decisiveness: 0,
        resilience: 2,
      },
      {
        answer:
          "출구를 빠르게 확보하고, 길을 터서 팀원들이 따라오도록 유도한다.",
        type: ["F", "P", "S", "I"],
        weight: [1, 2, 3, 2],
        survivalRate: 1,
        riskTaking: 4,
        morality: 2,
        situationalAwareness: 3,
        leadership: 3,
        decisiveness: 4,
        resilience: 2,
      },
      {
        answer: "당황한 나머지 모두를 남겨두고 앞다투어 먼저 도망간다.",
        type: ["N", "P", "F", "E"],
        weight: [1, 2, 1, 1],
        survivalRate: 3,
        riskTaking: -3,
        morality: -1,
        situationalAwareness: 1,
        leadership: -2,
        decisiveness: -3,
        resilience: -2,
      },
      {
        answer: "근처의 사물을 이용해 임시 가벽을 만들어 시간을 번다.",
        type: ["T", "S", "I", "P"],
        weight: [1, 3, 1, 2],
        survivalRate: 2,
        riskTaking: 0,
        morality: 1,
        situationalAwareness: 3,
        leadership: 1,
        decisiveness: 2,
        resilience: 1,
      },
      {
        answer: "함께 패닉하느라 아무것도 하지 못한다.",
        type: ["F", "S", "E", "J"],
        weight: [2, 1, 2, 2],
        survivalRate: -3,
        riskTaking: -3,
        morality: 0,
        situationalAwareness: -3,
        leadership: -3,
        decisiveness: -3,
        resilience: -3,
      },
    ],
  },

  {
    id: 9,
    question:
      "<span class='bold'>당신과 일행의 모든 노력에도 불구하고, 도주 시도는 실패로 돌아갔습니다.</span> 건물의 입구는 좀비들에게 뚫렸고, 사람들은 좁은 방 하나에 몰립니다.<br>" +
      "여러분과 좀비들 사이를 가로막은 유일한 물체인 오래된 나무 문 하나가, 연신 흔들리며 불안하게 삐걱거리는 소리를 냅니다. " +
      "<br>문을 긁는 소리가 점점 커져 갑니다. 사람들은 공포에 질려 있습니다. 좁은 공간 속에 가득한 긴장감, 서로를 바라보는 불안한 시선들. " +
      "<br>누군가 갑자기 숨죽여 이야기합니다." +
      "<br><span class='quote mt10'>'문이 곧 열릴 거야. 한 명이 유인해서 저것들을 다른 쪽으로 끌어내면 나머지는 탈출할 수 있을지도 몰라. 우리 모두 정문이 어디 있는진 알잖아.'</span>" +
      "<span class='quote mb10'>'여기서 다 죽을 순 없어... 제발, 다른 방법이 없다고.'</span>" +
      "공기가 팽팽히 얼어붙습니다." +
      "<span class='quote mt10 mb10'>'그럼 누가 나가?'</span> 또 다른 사람이 무겁게 묻자, 서로를 바라보며 갈등하는 눈빛이 교차합니다. <br>" +
      " 정말로 누군가 위험을 감수해야 할까요? 그렇다면 누가 그걸 감당해야 하나요? 아니면 다른 해결책이 있을까요?",

    options: [
      {
        answer: "최대한 희생 없이 모두를 살릴 방법을 찾자고 주장한다.",
        description:
          "이 선택지는 당신이 강력한 도덕적 신념을 가지고 있고, 모든 사람을 구하기 위해 끝까지 해결책을 찾으려 한다는 것을 보여줍니다. 다른 팀원들을 설득하기 위한 시간과 인내가 필요할 것입니다.",
        type: ["F", "N", "P", "E"],
        weight: [2, 1, 1, 1],
        survivalRate: 0,
        riskTaking: 3,
        morality: 3,
        situationalAwareness: -1,
        leadership: 2,
        decisiveness: 0,
        resilience: 0,
      },
      {
        answer:
          "실수를 저지른 사람이 책임을 져야 한다고 주장한다. 어쩌면 그에겐 속죄의 기회일지도 모른다.",
        description:
          "당신은 책임에 대해 철저하며, 실수를 저지른 사람에게 책임을 물어야 한다고 강하게 주장합니다. 이는 팀원들 사이에 갈등을 불러일으킬 수 있습니다.",
        type: ["T", "S", "J", "I"],
        weight: [2, 2, 1, 1],
        survivalRate: 3,
        riskTaking: -2,
        morality: 0,
        situationalAwareness: 2,
        leadership: 2,
        decisiveness: 2,
        resilience: 0,
      },
      {
        answer:
          "아무도 나서지 않으면 결국 모두 죽을 것이다. 좀비 유인을 자처하여 사람들을 살린다.",
        description:
          "자신의 목숨을 희생하여 나머지 팀원들이 탈출할 시간을 벌어줍니다. 당신은 고결한 희생정신을 가지고 있지만, 결국 다른 사람들은 생존하고 당신은 위험에 빠질 것입니다.",
        type: ["N", "P", "I", "F"],
        weight: [2, 1, 1, 1],
        survivalRate: -4,
        riskTaking: 3,
        morality: 4,
        situationalAwareness: 3,
        leadership: 3,
        decisiveness: 3,
        resilience: 2,
      },
      {
        answer:
          "참 좋은 생각이다. 문을 열고, 가장 가까이 있는 사람을 좀비 떼에 밀어넣는다.",
        type: ["T", "P", "E", "S"],
        weight: [2, 2, 1, 1],
        survivalRate: 3,
        riskTaking: -1,
        morality: -4,
        situationalAwareness: 3,
        leadership: -2,
        decisiveness: 3,
        resilience: 3,
      },
    ],
  },
  {
    id: 10,
    question:
      "<span class='bold'>당신은 무리와 함께 피난 중입니다.</span><br>" +
      "긴 여정과 점점 고조되는 스트레스 속에서 무리 내의 긴장감이 높아져 갑니다.<br>" +
      "이 과정에서 두 사람 간의 큰 싸움이 벌어졌습니다.<br>" +
      "한쪽은 무리의 리더로, 그는 식량을 아껴서 최대한 오래 버티며 새로운 피난처를 찾자는 계획을 주장하고 있습니다.<br>" +
      "반면, 예전에 당신의 생명을 구해준 친구는 리더의 계획이 비현실적이라며, 위험을 감수하더라도 조금 더 과감하고 빠르게 움직여야 한다고 주장합니다.<br>" +
      "리더의 결정은 무리 전체의 안전을 위한 신중한 계획이지만, 친구의 주장은 당신이 지금까지 생존할 수 있었던 이유이기도 합니다.<br>",

    options: [
      {
        answer:
          "리더는 리더니까. 그를 지지하며 무리의 안전과 질서를 우선시한다.",
        description:
          "당신은 리더의 계획이 신중하고 조직을 위한 최선의 선택이라고 믿고 그를 지지합니다. 이는 무리 내에서의 질서를 유지하지만, 친구와의 관계는 손상될 수 있습니다.",
        type: ["E", "S", "T", "J"],
        weight: [2, 1, 1, 2],
        survivalRate: 2,
        riskTaking: -2,
        morality: 1,
        situationalAwareness: 4,
        leadership: 0,
        decisiveness: 0,
        resilience: 2,
      },
      {
        answer: "친구를 지지하며 개인적 의리를 우선시한다.",
        description:
          "당신은 친구가 여러 차례 당신을 도왔고, 그의 판단이 더 맞다고 생각하며 친구의 편을 듭니다. 친구와의 신뢰는 유지되지만, 무리 내에서 리더의 권위를 흔들 수 있습니다.",
        type: ["F", "E", "N", "P"],
        weight: [2, 1, 1, 1],
        survivalRate: 1,
        riskTaking: 2,
        morality: 1,
        situationalAwareness: 1,
        leadership: 1,
        decisiveness: 2,
        resilience: 0,
      },
      {
        answer:
          "누구의 주장인지는 중요하지 않다. 가장 옳다고 생각하는 방향에 손을 들어준다.",
        description:
          "당신은 어느 쪽이 맞든 상관없이, 무리의 생존에 가장 이익이 되는 쪽을 선택합니다. 상황을 냉철하게 분석하여, 자원을 최적화하고 생존 확률을 높이려 합니다.",
        type: ["T", "J", "S", "I"],
        weight: [2, 1, 1, 2],
        survivalRate: 2,
        riskTaking: 0,
        morality: 1,
        situationalAwareness: 3,
        leadership: 2,
        decisiveness: 4,
        resilience: 2,
      },
      {
        answer:
          "논쟁에 아예 개입하지 않거나 그들의 싸움을 말리며 중립을 지킨다.",
        description:
          "당신은 싸움에 휘말리는 대신 중립을 선택하고, 상황을 지켜보며 판단합니다. 이 선택은 갈등을 피할 수 있지만, 양쪽 모두에게서 신뢰를 잃을 위험이 있습니다.",
        type: ["N", "I", "F", "P"],
        weight: [1, 1, 1, 1],
        survivalRate: -1,
        riskTaking: -2,
        morality: 1,
        situationalAwareness: -1,
        leadership: -2,
        decisiveness: -3,
        resilience: 0,
      },
      {
        answer:
          "리더에 대한 신뢰를 잃게 할 좋은 기회일지도 모른다. 갈등을 부추긴다.",
        description:
          "당신은 싸움을 이용해 둘 사이의 갈등을 부추기고, 혼란 속에서 리더와 친구 모두를 제거해 권력을 장악하려 합니다. 이는 매우 위험하고 비도덕적인 선택이지만, 성공하면 무리의 새로운 리더로 자리 잡을 수 있습니다.",
        type: ["N", "P", "E", "T"],
        weight: [1, 2, 2, 1],
        survivalRate: 1,
        riskTaking: 2,
        morality: -3,
        situationalAwareness: 2,
        leadership: 2,
        decisiveness: 2,
        resilience: 2,
      },
    ],
  },
  {
    id: 11,
    question:
      "<span class='bold'>당신은 좀비에게 물렸습니다.</span><br>" +
      "아직 감염이 시작되지는 않았지만, 시간이 지나면 그 끝이 다가올 것임을 당신은 알고 있습니다.<br>" +
      "당신의 옆에는 오랜 싸움을 함께 한 동료들이 있습니다.<br> 감염 사실이 밝혀진다면, 당신을 쫓아내거나, 그보다 더 끔찍한 결정을 내릴지도 모릅니다.<br>",
    options: [
      {
        answer:
          "정말 슬프지만, 물렸음을 고백하고 사랑하는 사람들과의 이별을 준비한다.",
        description:
          "당신은 감염 사실을 숨기지 않고 동료들에게 고백하며, 마지막 이별을 준비하려 합니다. 이는 도덕적 선택이지만, 감염이 확실한 상태에서 팀을 위험에 빠뜨릴 수 있습니다.",
        type: ["F", "S", "P", "I"],
        weight: [2, 2, 1, 2],
        survivalRate: -2,
        riskTaking: -3,
        morality: 2,
        situationalAwareness: 3,
        leadership: 0,
        decisiveness: 1,
        resilience: 3,
      },
      {
        answer:
          "감염 사실을 숨긴다. 지금 쫓겨나면 개죽음이다. 어쩌면 운 좋게 감염되지 않았을지도 모르고.",
        description:
          "당신은 감염 사실을 숨기고 팀에 남아있기로 합니다. 위험하지만, 감염이 확실하지 않다는 희망을 품고 행동합니다. 그러나 이는 다른 사람들에게 큰 위험이 될 수 있습니다.",
        type: ["E", "N", "J", "T"],
        weight: [1, 2, 1, 2],
        survivalRate: 1,
        riskTaking: 3,
        morality: -3,
        situationalAwareness: -1,
        leadership: 0,
        decisiveness: 0,
        resilience: -2,
      },
      {
        answer:
          "물렸음을 고백하고, 나를 묶어 달라고 한다. 그렇게 하면 다른 사람들을 위험에 빠뜨릴 일이 없을 것이다.",
        description:
          "당신은 감염 사실을 숨기지 않고, 동료들에게 자신을 묶어달라고 요청합니다. 당신이 더 이상 위험한 존재가 되지 않기 위한 선택입니다.",
        type: ["I", "N", "J", "T"],
        weight: [1, 1, 1, 2],
        survivalRate: 2,
        riskTaking: 2,
        morality: 1,
        situationalAwareness: 0,
        leadership: 2,
        decisiveness: 0,
        resilience: 2,
      },
      {
        answer: "감염이 진행되기 전에 팀원에게 자신을 죽여 달라고 부탁한다.",
        description:
          "당신은 동료에게 감염이 진행되기 전에 자신을 끝내달라고 부탁합니다. 이는 더 이상 다른 사람들을 위험에 빠뜨리고 싶지 않다는 결단입니다.",
        type: ["F", "S", "I", "P"],
        weight: [2, 1, 1, 1],
        survivalRate: -3,
        riskTaking: -1,
        morality: 2,
        situationalAwareness: 2,
        leadership: 1,
        decisiveness: 3,
        resilience: -2,
      },
      {
        answer:
          "굳이 알릴 필요도, 여기서 버틸 이유도 없다. 조용히 무리를 이탈한다.",
        description:
          "당신은 감염 사실이 밝혀지기 전에 혼자 떠나 스스로 최후를 맞이합니다. 이는 동료들에게 부담을 주지 않으려는 선택입니다.",
        type: ["I", "S", "T", "P"],
        weight: [2, 1, 1, 1],
        survivalRate: -2,
        riskTaking: -3,
        morality: 0,
        situationalAwareness: 1,
        leadership: 0,
        decisiveness: 2,
        resilience: -2,
      },
    ],
  },
];

export default questions;
