// src/data/riskMessages.js

const riskMessages = {
  ISTJ: {
    high:
      "평소 신중한 성향에도 불구하고, 당신은 필요한 경우 매우 과감한 결정을 내릴 수 있습니다. 계획이 완벽하다면 큰 위험도 감수할 준비가 되어 있습니다.",
    moderateHigh: "계획과 논리를 바탕으로 위험을 감수할 수 있습니다. 다만, 모든 상황을 신중하게 계산한 후에야 위험을 택합니다.",
    moderate:
      "당신은 위험을 적당히 감수하지만, 큰 위험보다는 더 안전하고 예측 가능한 경로를 선호합니다.",
    low: "당신은 위험을 최소화하려는 경향이 있으며, 대부분의 상황에서 보수적이고 안전한 선택을 합니다.",
    veryLow:
      "위험을 거의 감수하지 않으며, 언제나 안정적인 경로를 우선시합니다.",
  },
  ISFJ: {
    high:
      "타인을 보호하기 위해서라면 큰 위험도 감수할 수 있습니다. 당신은 상황에 따라 대담하게 행동할 수 있습니다.",
    moderateHigh: "타인을 위해 과감한 선택을 할 수 있지만, 대부분 신중하게 위험을 계산하고 행동합니다.",
    moderate:
      "위험을 감수하는 데 있어 신중하며, 타인을 보호하기 위해서라도 최대한 안전한 선택을 우선시합니다.",
    low: "위험을 피하려는 경향이 있으며, 항상 타인의 안전을 우선시하는 선택을 합니다.",
    veryLow: "위험을 극도로 피하고, 항상 가장 안전하고 신중한 결정을 내립니다.",
  },
  INFJ: {
    high:
      "당신은 이상을 이루기 위해 필요한 경우 매우 큰 위험도 감수할 수 있는 성향을 가지고 있습니다. 신념이 강해지면 더 대담해집니다.",
    moderateHigh: "당신은 이상주의적이지만, 현실적인 판단을 바탕으로 필요할 때 위험을 감수할 수 있습니다.",
    moderate:
      "위험을 감수하기보다는 신중한 결정을 선호하며, 가능하다면 더 안전한 경로를 택하려 합니다.",
    low: "위험을 감수하기보다는 이상을 지키기 위해 안전하고 예측 가능한 선택을 합니다.",
    veryLow:
      "위험을 거의 감수하지 않으며, 항상 도덕적 기준을 지키고자 신중하게 행동합니다.",
  },
  INTJ: {
    high:
      "당신은 목표를 달성하기 위해 매우 전략적으로 행동하며, 위험을 두려워하지 않고 과감한 결정을 내릴 수 있습니다.",
    moderateHigh: "당신은 위험을 감수할 준비가 되어 있으며, 계획적인 사고를 바탕으로 위험을 극복하려 합니다.",
    moderate:
      "당신은 위험을 감수하기보다는 논리적으로 분석한 후 안전한 경로를 선택하는 경향이 있습니다.",
    low: "위험을 감수하는 것보다는 철저한 분석과 계획을 통해 더 안전한 경로를 택하려 합니다.",
    veryLow:
      "위험을 거의 감수하지 않으며, 매우 신중하고 보수적인 결정을 내리는 경향이 있습니다.",
  },
  ISTP: {
    high:
      "위험을 두려워하지 않는 성향 덕분에 당신은 매우 과감한 결정을 내릴 수 있으며, 즉흥적으로 행동할 때도 있습니다.",
    moderateHigh: "당신은 위험을 감수할 수 있는 성향을 가지고 있으며, 문제를 해결하는 데 있어 빠른 판단을 합니다.",
    moderate:
      "위험을 감수하기보다는 상황을 분석한 후 적당히 안전한 선택을 하려 합니다.",
    low: "위험을 피하려고 노력하며, 신중한 결정을 내리는 것을 선호합니다.",
    veryLow: "위험을 거의 감수하지 않으며, 항상 안전한 결정을 우선시합니다.",
  },
  ISFP: {
    high:
      "당신은 자신과 타인의 안녕을 위해 큰 위험도 감수할 수 있으며, 상황에 따라 매우 대담하게 행동할 수 있습니다.",
    moderateHigh: "위험을 감수할 수 있지만, 주로 타인과의 관계를 고려하며 신중한 선택을 하려 합니다.",
    moderate: "위험을 적당히 감수하며, 상황에 따라 타인의 안전을 우선시합니다.",
    low: "위험을 피하려는 경향이 있으며, 타인의 안전을 우선시하는 선택을 하려 합니다.",
    veryLow: "위험을 감수하지 않으며, 항상 안전하고 조용한 선택을 선호합니다.",
  },
  INFP: {
    high:
      "당신은 자신의 신념을 지키기 위해 큰 위험도 기꺼이 감수할 수 있는 성향을 가지고 있습니다.",
    moderateHigh: "위험을 감수할 수 있으며, 자신의 가치와 신념을 지키기 위해 필요한 경우에는 대담한 선택을 할 수 있습니다.",
    moderate:
      "위험을 적당히 감수하며, 주로 자신의 도덕적 기준을 바탕으로 신중하게 결정을 내립니다.",
    low: "위험을 피하려는 경향이 있으며, 도덕적 갈등을 겪을 경우 더욱 신중해집니다.",
    veryLow:
      "위험을 전혀 감수하지 않으며, 언제나 도덕적이고 안전한 결정을 내립니다.",
  },
  ENFP: {
    high:
      "즉흥적이고 대담한 성향 덕분에 위험을 두려워하지 않으며, 과감한 결정을 내릴 수 있습니다.",
    moderateHigh: "위험을 감수하는 경향이 있지만, 즉흥적으로 상황에 맞게 대처하려고 합니다.",
    moderate:
      "당신은 위험을 적당히 감수하며, 상황에 따라 신중하게 결정을 내립니다.",
    low: "위험을 감수하기보다는 안전하고 예측 가능한 선택을 선호합니다.",
    veryLow:
      "위험을 거의 감수하지 않으며, 언제나 안정적인 경로를 우선시합니다.",
  },
  ESTJ: {
    high:
      "당신은 책임감이 강하며, 리더십을 발휘하기 위해 위험을 기꺼이 감수할 수 있습니다.",
    moderateHigh: "당신은 위험을 감수할 수 있으며, 조직의 목표를 달성하기 위해 과감한 결정을 내릴 수 있습니다.",
    moderate:
      "당신은 주로 신중하게 결정을 내리지만, 필요할 때는 위험을 감수할 수 있습니다.",
    low: "위험을 감수하기보다는 신중하게 상황을 분석하고 안전한 결정을 내리려 합니다.",
    veryLow:
      "위험을 거의 감수하지 않으며, 언제나 보수적이고 안전한 결정을 내리는 경향이 있습니다.",
  },
  ESFJ: {
    high:
      "당신은 타인을 보호하고 돕기 위해 위험을 두려워하지 않고, 과감한 결정을 내릴 수 있습니다.",
    moderateHigh: "당신은 위험을 감수할 수 있지만, 주로 타인의 안전을 최우선으로 고려하여 신중하게 결정을 내립니다.",
    moderate:
      "위험을 적당히 감수하며, 타인의 안전을 중요시하면서도 필요할 때는 과감하게 행동할 수 있습니다.",
    low: "위험을 피하려는 경향이 있으며, 타인을 돕는 데 있어 주로 안전한 선택을 합니다.",
    veryLow:
      "위험을 거의 감수하지 않으며, 언제나 타인의 안전을 우선시하여 매우 신중한 결정을 내립니다.",
  },
  ENTP: {
    high:
      "당신은 새로운 아이디어와 혁신을 추구하는 성향 덕분에 위험을 두려워하지 않고 대담한 결정을 내립니다.",
    moderateHigh: "당신은 위험을 감수할 수 있으며, 새로운 도전을 위해 필요한 경우 대담하게 행동할 수 있습니다.",
    moderate:
      "당신은 주로 신중하게 결정을 내리지만, 필요할 때는 위험을 감수할 수 있습니다.",
    low: "위험을 감수하기보다는 신중하게 상황을 분석하고 안전한 결정을 내리려 합니다.",
    veryLow: "위험을 거의 감수하지 않으며, 언제나 안전한 선택을 우선시합니다.",
  },
  ENTJ: {
    high:
      "당신은 리더로서 과감한 결정을 내릴 수 있으며, 위험을 두려워하지 않고 행동합니다.",
    moderateHigh: "당신은 상황에 맞춰 과감한 결정을 내리며, 필요한 경우 위험을 감수할 수 있습니다.",
    moderate:
      "당신은 신중하게 위험을 평가하며, 필요할 때는 위험을 감수할 수 있습니다.",
    low: "위기 상황에서는 신중한 편이며, 과감한 결정보다는 안전한 선택을 선호합니다.",
    veryLow:
      "당신은 위험을 감수하는 것을 매우 꺼리며, 안정적인 선택을 우선시합니다.",
  },
  ESTP: {
    high:
      "당신은 즉각적인 결정을 내리며 위험을 두려워하지 않는 성향을 가지고 있습니다. 생존을 위해 과감한 행동을 서슴지 않습니다.",
    moderateHigh: "당신은 위험을 감수할 수 있으며, 상황에 따라 즉각적인 결정을 내리려 합니다.",
    moderate:
      "위험을 적당히 감수하며, 주로 신중하게 결정을 내리지만 필요할 때는 대담하게 행동할 수 있습니다.",
    low: "위험을 감수하기보다는 안전하고 예측 가능한 선택을 선호합니다.",
    veryLow: "위험을 거의 감수하지 않으며, 언제나 안정적인 경로를 택합니다.",
  },
  ESFP: {
    high:
      "당신은 즉흥적이고 대담한 성향 덕분에 위험을 두려워하지 않고 과감한 결정을 내릴 수 있습니다.",
    moderateHigh: "당신은 위험을 감수하는 경향이 강하며, 상황에 따라 즉흥적인 결정을 내릴 수 있습니다.",
    moderate: "위험을 적당히 감수하며, 상황에 맞게 신중하게 접근하려 합니다.",
    low: "위험을 감수하기보다는 안전하고 예측 가능한 결정을 내리려 합니다.",
    veryLow:
      "위험을 거의 감수하지 않으며, 언제나 안전한 결정을 내리는 경향이 있습니다.",
  },
  ENFJ: {
    high:
      "당신은 다른 사람들을 돕기 위해서라면 큰 위험도 감수할 수 있으며, 리더로서 대담한 결정을 내릴 수 있습니다.",
    moderateHigh: "당신은 위험을 감수할 수 있지만, 주로 타인의 안전과 행복을 고려하여 신중한 결정을 내립니다.",
    moderate:
      "위험을 적당히 감수하며, 타인의 안전을 최우선으로 생각하면서도 필요할 때는 과감하게 행동할 수 있습니다.",
    low: "위험을 피하려는 경향이 있으며, 타인을 돕는 데 있어 주로 안전한 결정을 내립니다.",
    veryLow:
      "위험을 거의 감수하지 않으며, 언제나 타인의 안전을 우선시하여 매우 신중한 결정을 내립니다.",
  },
  INTP: {
    high:
      "당신은 논리적 사고를 바탕으로 과감하게 위험을 감수할 수 있습니다. 분석을 통해 빠르게 결정을 내릴 수 있습니다.",
    moderateHigh: "위험을 감수할 수 있지만, 신중하게 분석한 후에만 결정을 내립니다.",
    moderate:
      "위험을 적당히 감수하며, 상황에 따라 논리적으로 판단하여 행동합니다.",
    low: "위험을 피하려는 경향이 있으며, 분석적으로 안전한 결정을 내립니다.",
    veryLow:
      "위험을 거의 감수하지 않으며, 항상 안전하고 신중한 결정을 내립니다.",
  },
};

export default riskMessages;
