import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import html2canvas from "html2canvas";
import results from "../data/results"; // 기존 성격 유형에 대한 데이터
import mbtiBaseTraits from "../data/mbtiBaseTraits"; // 기본 성격 특성 데이터
import survivalMessages from "../data/survivalMessages"; // 생존 메시지
import riskMessages from "../data/riskMessages"; // 위험 감수 메시지
import moralityMessages from "../data/moralityMessages"; // 모럴리티 메시지
import situationalAwarenessMessages from "../data/situationalAwarenessMessages"; // 상황 인식 메시지
import leadershipMessages from "../data/leadershipMessages"; // 리더십 메시지
import decisionMakingMessages from "../data/decisionMakingMessages"; // 결단력 메시지
import resilienceMessages from "../data/resilienceMessages"; // 회복력 메시지

function Result() {
  const location = useLocation();
  const {
    mbtiType = "INFJ", // 기본 MBTI 예시로 설정
    survivalRate = 15, // 임시 생존률 예시
    riskTaking = 2, // 임시 위험 감수 성향 예시
    morality = 1, // 임시 도덕적 판단 예시
    situationalAwareness = 2, // 임시 상황 인식 예시
    leadership = 3, // 임시 리더십 예시
    decisionMaking = 2, // 임시 결단력 예시
    resilience = 1, // 임시 회복력 예시
  } = location.state || {}; // 사용자 선택 데이터가 없을 경우 예시 데이터 사용

  // 검사 결과 수치를 콘솔에 출력하는 부분
  useEffect(() => {
    console.log("MBTI Type:", mbtiType);
    console.log("Survival Rate:", survivalRate);
    console.log("Risk Taking:", riskTaking);
    console.log("Morality:", morality);
    console.log("Situational Awareness:", situationalAwareness);
    console.log("Leadership:", leadership);
    console.log("Decision Making:", decisionMaking);
    console.log("Resilience:", resilience);
  }, [
    mbtiType,
    survivalRate,
    riskTaking,
    morality,
    situationalAwareness,
    leadership,
    decisionMaking,
    resilience,
  ]);

  if (!results[mbtiType]) {
    return <p>결과를 계산할 수 없습니다. 다시 시도해 주세요.</p>;
  }

  // 기존 MBTI 분석 데이터 불러오기
  const {
    title,
    description,
    survivalStyle,
    strengths,
    weaknesses,
    recommendedStrategy,
  } = results[mbtiType];

  const baseTraits = mbtiBaseTraits[mbtiType] || {};

  // 특정 성향을 비교하는 함수 (기존 + 새롭게 추가된 피드백)
  const compareTrait = (userTrait, baseTrait = 0, traitName) => {
    if (!baseTrait) baseTrait = 0; // baseTrait가 undefined이면 0으로 초기화
    if (userTrait >= baseTrait + 10) {
      return (
        <span style={{ color: "#ADD8E6" }}>
          일반적인 {title}에 비해 {traitName} 성향이 매우 높은 편입니다.
        </span>
      );
    }
    if (userTrait >= baseTrait + 5) {
      return (
        <span style={{ color: "#ADD8E6" }}>
          여타 {title}에 비하면, {traitName} 성향이 다소 높습니다. 일반적인
          성향을 약간 넘어서는 경향을 보입니다.
        </span>
      );
    }
    if (userTrait >= baseTrait - 5) {
      return (
        <span style={{ color: "#ADD8E6" }}>
          {traitName} 성향이 {title}의 일반적인 성향과 일치합니다.
        </span>
      );
    }
    return (
      <span style={{ color: "#ADD8E6" }}>
        여타 {title}에 비하면, {traitName} 성향이 상대적으로 낮습니다.
      </span>
    );
  };

  // 생존 메시지, 위험 메시지, 모럴리티 메시지를 반환하는 함수
  const getMessage = (messages, rate, personalityType) => {
    if (!messages[personalityType])
      return "해당 성격 유형에 대한 데이터가 없습니다.";

    const level =
      rate >= 20
        ? "high"
        : rate >= 10
        ? "moderateHigh"
        : rate >= 0
        ? "moderate"
        : rate >= -10
        ? "low"
        : "veryLow";

    return (
      messages[personalityType][level] ||
      rate + level + "데이터를 불러올 수 없습니다."
    );
  };

  // 결과 페이지를 이미지로 캡처하고 파일로 저장하는 함수
  const saveAsImage = () => {
    const input = document.getElementById("result-page");
    if (!input) {
      console.error("Element with id 'result-page' not found.");
      return;
    }

    html2canvas(input, { scale: window.devicePixelRatio }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = imgData;
        link.download = "result.png";

        // 모바일 환경 체크
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
          // 모바일에서는 새 창에서 이미지를 보여줌
          window.open(imgData, "_blank");
        } else {
          // 데스크탑 환경에서는 이미지 다운로드
          link.click();
        }
      } else {
        // 링크 다운로드가 안 될 경우 새 창에서 이미지 열기
        window.open(imgData);
      }
    });
  };

  // 트위터로 결과 공유하는 함수
  const shareOnTwitter = () => {
    const tweetText = `나는 ${title} 유형입니다. 테스트하고 본인의 생존률과 성격을 확인해보세요. #좀비아포칼립스 #생존유형테스트`;
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}&url=https://cherryducklin.pe.kr/`;

    window.open(tweetUrl, "_blank");
  };

  return (
    <div style={styles.result_wrap}>
      <div id={"result-page"} style={styles.container}>
        <h2 style={styles.title}>{title}</h2>
        <p style={styles.description}>{description}</p>

        {/* 애드센스 심사봇을 위한 안내 문구 */}
        <p style={{ fontSize: "0.8rem", color: "#888", textAlign: "center" }}>
          이 페이지는 사용자 테스트 결과를 기반으로 생존률, 위험 감수 성향,
          도덕적 판단, 상황 인식, 리더십, 결단력, 회복력에 대한 종합 평가를
          제공합니다. 각각의 테스트 결과는 사용자가 선택한 답변을 기반으로
          계산됩니다.
        </p>

        <div style={styles.section}>
          <h3>생존 스타일</h3>
          <p>{survivalStyle}</p>
        </div>

        <div style={styles.section}>
          <h3>강점</h3>
          <p>{strengths}</p>
        </div>

        <div style={styles.section}>
          <h3>약점</h3>
          <p>{weaknesses}</p>
        </div>

        <div style={styles.section}>
          <h3>추천 전략</h3>
          <p>{recommendedStrategy}</p>
        </div>

        {/* 종합 평가 한 문단으로 통합 */}
        <div style={styles.section}>
          <h3>종합 평가</h3>
          <p style={styles.trait}>
            당신은 좀비 아포칼립스 상황에서 <strong>{title}</strong> 유형으로,
            {compareTrait(survivalRate, baseTraits.survivalRate, "생존률")}{" "}
            <strong>
              {getMessage(survivalMessages, survivalRate, mbtiType)}
            </strong>
            <br></br>
            {compareTrait(
              riskTaking,
              baseTraits.riskTaking,
              "위험 감수 성향"
            )}{" "}
            <strong>{getMessage(riskMessages, riskTaking, mbtiType)}</strong>{" "}
            <br></br>
            {compareTrait(morality, baseTraits.morality, "도덕적 판단")}{" "}
            {getMessage(moralityMessages, morality, mbtiType)} <br></br>
            <br></br>
            {getMessage(
              situationalAwarenessMessages,
              situationalAwareness,
              mbtiType
            )}{" "}
            {getMessage(leadershipMessages, leadership, mbtiType)}{" "}
            {getMessage(decisionMakingMessages, decisionMaking, mbtiType)}{" "}
            {getMessage(resilienceMessages, resilience, mbtiType)}
          </p>
        </div>

        <Link to="/" style={styles.link}>
          <button style={styles.button}>다시 테스트하기</button>
        </Link>
        {/* 결과 페이지를 이미지로 저장하는 버튼 */}
        <button onClick={saveAsImage} style={styles.button}>
          결과 저장하기
        </button>

        {/* 트위터 공유하기 버튼 */}
        <button onClick={shareOnTwitter} style={styles.button}>
          트위터에 공유하기
        </button>
      </div>
    </div>
  );
}

const styles = {
  result_wrap: {
    backgroundColor: "#282c34",
  },
  container: {
    display: "flex",
    backgroundColor: "#282c34",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    color: "#ffffff",
    textAlign: "center",
    maxWidth: "600px",
    margin: "0 auto",
    borderRadius: "10px",
  },
  title: {
    fontSize: "2rem",
    marginBottom: "10px",
    color: "#61dafb",
  },
  description: {
    fontSize: "1.2rem",
    marginBottom: "20px",
    lineHeight: "1.5",
  },
  section: {
    backgroundColor: "#444b53",
    borderRadius: "8px",
    padding: "15px",
    margin: "10px 0",
    width: "100%",
    textAlign: "left",
  },
  trait: {
    marginBottom: "10px",
  },
  message: {
    display: "block",
    marginTop: "5px",
    fontSize: "0.9rem",
    color: "#61dafb",
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    fontWeight: "bold",
    backgroundColor: "#61dafb",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    color: "#ffffff",
    marginTop: "20px",
  },
  link: {
    textDecoration: "none",
  },
};

export default Result;
