const moralityMessages = {
  ISTJ: {
    high: "규칙과 질서를 중시하는 당신은 위기 상황에서도 윤리적 기준을 철저히 지킵니다. 타인의 생존과 안전을 최우선으로 고려하며, 규칙에서 벗어난 행동을 피하려 합니다.",
    moderateHigh: "윤리적 가치를 중요시하고, 극단적인 상황에서도 다른 사람의 안전을 우선시하려는 성향이 있습니다.",
    moderate: "윤리적 기준을 지키면서도 상황에 따라 유연하게 대처할 수 있으며, 필요할 경우 자신의 안전을 우선할 수 있습니다.",
    low: "위기 상황에서는 자신의 생존을 우선시하며, 윤리적 기준보다는 실질적인 생존 가능성을 더 중시하는 편입니다.",
    veryLow: "필요하다면 윤리적 기준을 벗어나서라도 생존을 위해 모든 수단을 동원할 수 있는 성향이 강합니다."
  },
  ISFJ: {
    high: "타인을 돌보는 성향이 강한 당신은 어떤 상황에서도 윤리적 가치를 절대적으로 지키려 합니다. 타인의 안전을 최우선으로 생각하며, 자신을 희생하는 선택도 고려합니다.",
    moderateHigh: "윤리적 기준을 지키면서도 실질적인 상황에 맞게 대처할 수 있습니다. 타인의 안전을 우선시하지만, 상황에 따라 유연한 판단도 가능합니다.",
    moderate: "생존을 위해 어느 정도 윤리적 유연성을 가지지만, 타인의 안전과 자신의 생존을 적절히 고려합니다.",
    low: "자신과 타인의 생존을 함께 고려하지만, 필요할 때는 자신의 생존을 더 우선시할 수 있습니다.",
    veryLow: "타인의 생존보다 자신의 안전을 더 중시하며, 극단적인 상황에서 윤리적 기준을 벗어날 수 있는 성향이 강합니다."
  },
  INFJ: {
    high: "타인의 감정을 깊이 이해하는 당신은 윤리적 판단을 매우 중요시하며, 극단적인 상황에서도 타인의 생존을 위해 노력합니다. 생존보다 도덕적 원칙이 우선입니다.",
    moderateHigh: "윤리적 가치와 타인의 안전을 중시하며, 상황에 따라 자신의 생존보다 타인을 돕는 선택을 할 수 있습니다.",
    moderate: "윤리적 판단과 실질적인 생존을 균형 있게 고려하며, 필요할 때는 유연하게 대처합니다.",
    low: "위기 상황에서 자신의 생존을 더 우선시하며, 윤리적 판단이 약간은 뒤로 밀릴 수 있습니다.",
    veryLow: "도덕성을 고려하기보다는 자신의 생존을 최우선으로 두며, 필요하다면 윤리적 기준을 무시할 수 있습니다."
  },
  INTJ: {
    high: "논리적이고 체계적인 당신은 윤리적 가치를 지키며, 다른 사람들의 생존도 중요하게 여깁니다. 그러나 극단적인 상황에서는 상황에 맞는 현실적 판단도 함께 고려합니다.",
    moderateHigh: "윤리적 판단을 중요시하지만, 생존을 위해 필요한 경우 유연한 판단을 내릴 수 있습니다.",
    moderate: "윤리적 기준과 생존 가능성을 적절히 고려하며, 필요할 경우 어느 정도 윤리적 유연성을 발휘합니다.",
    low: "위기 상황에서는 자신의 생존을 우선시하며, 윤리적 판단보다 실질적인 생존 가능성을 더 중시하는 편입니다.",
    veryLow: "생존을 위해 윤리적 기준을 무시할 수 있으며, 실용적인 해결책을 우선시하는 성향이 강합니다."
  },
  ISTP: {
    high: "위기 상황에서도 윤리적 판단을 중시하며, 생존을 위해서라도 도덕적 가치를 지키려 합니다.",
    moderateHigh: "윤리적 가치를 지키면서도 실용적인 대처를 중시합니다. 타인의 안전을 고려하나, 필요할 경우 자신의 생존을 더 우선시할 수 있습니다.",
    moderate: "상황에 맞게 윤리적 기준과 생존 가능성을 균형 있게 고려하며, 필요할 때는 유연하게 판단합니다.",
    low: "자신의 생존을 더 우선시하며, 상황에 따라 윤리적 기준을 타협할 수 있습니다.",
    veryLow: "위기 상황에서는 생존을 위해 도덕적 기준을 무시하고 과감한 선택을 할 수 있습니다."
  },
  ISFP: {
    high: "타인을 돌보는 성향 덕분에 윤리적 가치를 철저히 지키며, 다른 사람들의 생존과 안전을 최우선으로 고려합니다.",
    moderateHigh: "윤리적 기준을 지키면서도 자신과 타인의 생존을 균형 있게 고려합니다.",
    moderate: "필요할 경우 윤리적 기준을 적당히 타협하며, 생존을 위한 결정을 내릴 수 있습니다.",
    low: "자신의 생존을 더 우선시하며, 윤리적 기준을 때때로 타협할 수 있습니다.",
    veryLow: "자신의 생존을 위해 윤리적 기준을 무시할 수 있으며, 극단적인 상황에서 과감한 선택을 할 수 있습니다."
  },
  INFP: {
    high: "윤리적 기준을 매우 중요하게 여기며, 위기 상황에서도 타인의 생존을 우선시하는 경향이 강합니다. 도덕적 판단이 생존보다 우선할 수 있습니다.",
    moderateHigh: "윤리적 가치를 중요시하며, 극단적인 상황에서도 타인의 안전을 먼저 생각합니다.",
    moderate: "생존을 위해 어느 정도 윤리적 유연성을 가지며, 상황에 따라 타인의 안전과 자신의 생존을 모두 고려합니다.",
    low: "위기 상황에서 자신의 생존을 더 중시하며, 윤리적 판단보다 실질적인 생존을 우선할 수 있습니다.",
    veryLow: "윤리적 기준을 무시하고 자신의 생존을 위해 모든 수단을 동원할 수 있는 성향이 강합니다."
  },
  INTP: {
    high: "윤리적 가치와 논리적 판단을 동시에 고려하며, 위기 상황에서도 타인의 생존을 중요하게 여깁니다.",
    moderateHigh: "윤리적 가치를 중요시하면서도 실질적인 생존을 고려하며, 필요할 경우 유연한 판단을 내릴 수 있습니다.",
    moderate: "윤리적 기준과 실질적인 생존을 균형 있게 고려하며, 상황에 맞게 유연하게 판단합니다.",
    low: "자신의 생존을 더 우선시하며, 상황에 따라 윤리적 기준을 타협할 수 있습니다.",
    veryLow: "생존을 위해 윤리적 기준을 무시할 수 있으며, 논리적이고 실용적인 해결책을 우선시합니다."
  },
  ESTP: {
    high: "위기 상황에서도 윤리적 가치를 철저히 지키며, 타인의 생존과 안전을 최우선으로 고려합니다.",
    moderateHigh: "윤리적 기준을 지키면서도 빠르게 대처하며, 필요할 경우 타인을 보호하는 선택을 합니다.",
    moderate: "윤리적 기준과 생존 가능성을 균형 있게 고려하며, 필요할 때는 유연하게 판단합니다.",
    low: "자신의 생존을 더 우선시하며, 상황에 따라 윤리적 기준을 타협할 수 있습니다.",
    veryLow: "위기 상황에서는 생존을 위해 도덕적 기준을 무시하고 과감한 결정을 내릴 수 있습니다."
  },
  ESFP: {
    high: "타인을 돌보는 성향 덕분에 윤리적 가치를 최우선으로 여기며, 타인의 안전과 생존을 중시합니다.",
    moderateHigh: "윤리적 기준을 지키면서도 상황에 맞게 유연하게 대처할 수 있습니다.",
    moderate: "윤리적 기준과 실질적인 생존을 적절히 균형 있게 고려하며, 필요할 때는 유연하게 판단합니다.",
    low: "위기 상황에서 자신의 생존을 더 우선시하며, 필요할 때는 윤리적 기준을 타협할 수 있습니다.",
    veryLow: "생존을 위해 윤리적 기준을 무시하고 모든 수단을 동원할 수 있는 성향이 강합니다."
  },
  ENFP: {
    high: "윤리적 가치를 매우 중요하게 여기며, 타인의 생존과 안전을 우선시합니다. 위기 상황에서도 윤리적 판단이 생존보다 중요하게 여겨질 수 있습니다.",
    moderateHigh: "윤리적 가치를 중요시하면서도 상황에 맞게 유연하게 대처할 수 있습니다.",
    moderate: "윤리적 기준과 생존 가능성을 균형 있게 고려하며, 필요할 때는 유연하게 판단할 수 있습니다.",
    low: "자신의 생존을 더 우선시하며, 상황에 따라 윤리적 기준을 타협할 수 있습니다.",
    veryLow: "생존을 위해 윤리적 기준을 무시하고 과감한 결정을 내릴 수 있는 성향이 강합니다."
  },
  ENTP: {
    high: "윤리적 가치를 중시하면서도 실질적인 해결책을 찾으려 노력하며, 타인의 생존을 중요하게 여깁니다.",
    moderateHigh: "윤리적 기준을 지키면서도 필요할 때는 유연한 판단을 내리며, 타인의 생존을 위해 노력합니다.",
    moderate: "윤리적 기준과 생존 가능성을 적절히 고려하며, 필요할 때는 유연하게 대처할 수 있습니다.",
    low: "자신의 생존을 더 우선시하며, 필요할 때는 윤리적 기준을 타협할 수 있습니다.",
    veryLow: "생존을 위해 윤리적 기준을 무시하고, 실질적인 해결책을 우선시하는 성향이 강합니다."
  },
  ESTJ: {
    high: "윤리적 가치와 규율을 매우 중시하며, 타인의 안전과 생존을 최우선으로 생각합니다. 위기 상황에서도 윤리적 판단을 지키려 노력합니다.",
    moderateHigh: "윤리적 기준을 지키면서도 실질적인 생존을 고려하며, 타인을 돕기 위해 노력합니다.",
    moderate: "윤리적 기준과 생존 가능성을 균형 있게 고려하며, 필요할 때는 유연한 판단을 내릴 수 있습니다.",
    low: "자신의 생존을 더 우선시하며, 상황에 따라 윤리적 기준을 타협할 수 있습니다.",
    veryLow: "생존을 위해 윤리적 기준을 무시하고 모든 수단을 동원할 수 있는 성향이 강합니다."
  },
  ESFJ: {
    high: "타인의 안전과 생존을 최우선으로 생각하며, 위기 상황에서도 윤리적 판단을 철저히 지키려 합니다.",
    moderateHigh: "윤리적 기준을 중시하면서도 상황에 맞게 유연하게 대처할 수 있습니다.",
    moderate: "윤리적 기준과 생존 가능성을 적절히 고려하며, 필요할 때는 유연하게 대처할 수 있습니다.",
    low: "자신의 생존을 더 우선시하며, 상황에 따라 윤리적 기준을 타협할 수 있습니다.",
    veryLow: "생존을 위해 윤리적 기준을 무시하고 과감한 결정을 내릴 수 있는 성향이 강합니다."
  },
  ENFJ: {
    high: "타인을 돕는 성향 덕분에 윤리적 가치를 매우 중요하게 여기며, 위기 상황에서도 타인의 안전과 생존을 최우선으로 생각합니다.",
    moderateHigh: "윤리적 기준을 지키면서도 상황에 맞게 유연하게 대처하며 타인의 생존을 중시합니다.",
    moderate: "윤리적 기준과 생존 가능성을 적절히 고려하며, 필요할 때는 유연하게 판단할 수 있습니다.",
    low: "자신의 생존을 더 우선시하며, 상황에 따라 윤리적 기준을 타협할 수 있습니다.",
    veryLow: "생존을 위해 윤리적 기준을 무시하고 과감한 결정을 내릴 수 있는 성향이 강합니다."
  },
  ENTJ: {
    high: "윤리적 가치를 매우 중요하게 여기며, 타인의 생존과 안전을 중시하지만 필요할 때는 전략적으로 판단하여 행동합니다.",
    moderateHigh: "윤리적 기준을 지키면서도 상황에 맞게 유연하게 대처하며 타인의 생존을 중시합니다.",
    moderate: "윤리적 기준과 생존 가능성을 적절히 고려하며, 필요할 때는 유연한 판단을 내릴 수 있습니다.",
    low: "자신의 생존을 더 우선시하며, 상황에 따라 윤리적 기준을 타협할 수 있습니다.",
    veryLow: "생존을 위해 윤리적 기준을 무시하고 실질적인 해결책을 우선시하는 성향이 강합니다."
  },
};

export default moralityMessages;
