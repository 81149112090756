const leadershipMessages = {
  ISTJ: {
    high: "당신은 체계적이고 계획적인 성향으로, 리더로서의 역할을 완벽하게 수행하며 조직을 이끌어 나갑니다. 어려운 상황에서도 안정적인 리더십을 발휘합니다.",
    moderateHigh: "당신은 철저한 계획을 바탕으로 주도적인 역할을 수행할 수 있으며, 팀을 이끌 때 매우 신뢰받습니다.",
    moderate: "상황에 따라 리더십을 발휘할 수 있지만, 지나친 책임을 지기보다는 자신의 역할에 충실하는 경향이 있습니다.",
    low: "리더로 나서는 것보다 맡은 역할을 충실히 이행하는 데 집중하며, 남의 의견을 따르는 편입니다.",
    veryLow: "리더 역할보다는 뒤에서 지원하는 것을 선호하며, 결정을 내리는 것을 부담스러워할 수 있습니다."
  },
  ENFP: {
    high: "당신은 창의적이고 사람들과 소통을 잘하여 자연스럽게 리더로 나섭니다. 팀원들의 의견을 존중하며 모두가 만족할 수 있는 결정을 이끌어냅니다.",
    moderateHigh: "당신은 사람들을 격려하고 이끄는 데 능숙하며, 팀을 유연하게 이끌어 나갈 수 있습니다.",
    moderate: "팀 내에서 리더로 나서기보다는, 동료들과 협력하면서 리더십을 발휘할 기회를 찾는 편입니다.",
    low: "리더로 나서는 것보다는 조화를 중시하며, 다른 사람들의 의견을 듣고 따르는 경향이 있습니다.",
    veryLow: "리더십을 발휘하기보다는, 팀의 일원으로서 자신의 역할을 충실히 수행하는 것을 선호합니다."
  },
  ENTJ: {
    high: "당신은 천성적인 리더로서 결단력과 카리스마로 팀을 이끌며, 어려운 상황에서도 주도적으로 문제를 해결합니다.",
    moderateHigh: "리더로서의 역할을 적극적으로 수행하며, 팀을 이끌고 목표를 달성하는 데 있어 탁월한 능력을 발휘합니다.",
    moderate: "상황에 따라 리더십을 발휘할 수 있으며, 주어진 역할을 충실히 이행하려는 성향이 있습니다.",
    low: "리더로서의 역할을 수행하기보다는 팀의 목표를 따르고, 자신의 역할에 집중합니다.",
    veryLow: "리더 역할을 수행하는 것보다는 다른 사람들의 결정을 따르는 것을 선호할 수 있습니다."
  },
  INFP: {
    high: "당신은 다른 사람들의 의견을 존중하며 그들을 이끄는 리더로서, 감정적으로 동료들에게 큰 지지를 받습니다.",
    moderateHigh: "당신은 팀원들의 감정적 지원을 중요하게 생각하며, 사람들을 격려하는 데 탁월한 능력을 보입니다.",
    moderate: "리더로 나서는 것보다 협력적인 분위기를 중요시하며, 주로 다른 사람들과 협력하는 데 중점을 둡니다.",
    low: "리더로서의 역할보다는 팀 내에서 조용히 자신의 역할을 수행하는 것을 선호합니다.",
    veryLow: "리더로 나서는 것에 부담을 느끼며, 주로 다른 사람들의 의견을 따르는 편입니다."
  },
  ESTP: {
    high: "당신은 역동적인 리더로서 빠른 결단력과 실행력을 바탕으로 팀을 이끌고, 실전에서 강한 리더십을 발휘합니다.",
    moderateHigh: "결단력 있게 팀을 이끌며, 필요할 때 주저하지 않고 리더십을 발휘할 수 있습니다.",
    moderate: "리더로서의 역할을 수행할 수 있지만, 상황에 따라 필요할 때만 리더십을 발휘하려는 경향이 있습니다.",
    low: "리더보다는 팀의 일원으로서 빠른 판단력으로 기여하는 것을 더 선호합니다.",
    veryLow: "리더 역할보다는 팀원으로서의 역할을 충실히 이행하며, 주도적으로 나서기보다는 지원하는 편입니다."
  },
  ISFP: {
    high: "당신은 사람들과의 조화를 중시하며, 팀원들에게 따뜻하고 격려적인 리더십을 발휘합니다.",
    moderateHigh: "리더로서의 역할을 맡을 수 있으며, 팀의 분위기를 부드럽게 이끌고 가는 데 강점이 있습니다.",
    moderate: "팀 내에서 협력적인 역할을 맡으며, 필요할 때만 리더십을 발휘합니다.",
    low: "리더보다는 팀원으로서의 역할을 선호하며, 주로 뒤에서 지원하는 역할을 맡습니다.",
    veryLow: "리더로 나서는 것을 꺼려하며, 다른 사람들의 결정을 따르는 경향이 있습니다."
  },
  INFJ: {
    high: "당신은 다른 사람들의 감정을 잘 파악하며, 조용하지만 확고한 리더십을 발휘합니다. 팀원들을 격려하고 그들의 성장에 기여하는 것을 중요시합니다.",
    moderateHigh: "당신은 팀원들의 감정적 상태를 고려하며, 그들을 이끄는 데 탁월한 능력을 보입니다.",
    moderate: "리더 역할을 맡기보다는 주로 조언자나 지원자의 역할을 맡으며, 필요한 순간에만 리더십을 발휘합니다.",
    low: "리더로 나서기보다는 다른 사람들의 의견을 듣고 따르며, 자신의 역할에 집중합니다.",
    veryLow: "리더 역할을 수행하는 것보다는 다른 사람들을 지지하며 팀을 돕는 것을 선호합니다."
  },
  ENTP: {
    high: "당신은 혁신적인 사고와 도전을 즐기며, 빠르게 변하는 상황에서 리더로서 탁월한 결정을 내립니다.",
    moderateHigh: "팀을 이끌며 새로운 아이디어를 제시하고, 팀원들에게 큰 영감을 주는 역할을 합니다.",
    moderate: "필요할 때 리더 역할을 맡지만, 주로 창의적이고 혁신적인 해결책을 제시하는 데 중점을 둡니다.",
    low: "리더로서의 역할을 맡기보다는 팀원으로서의 기여에 집중합니다.",
    veryLow: "리더보다는 팀 내에서 혁신적인 아이디어를 제시하며 기여하는 것을 선호합니다."
  },
  ESFJ: {
    high: "당신은 타인에 대한 배려와 책임감을 바탕으로 팀을 이끌며, 사람들의 신뢰를 받는 리더로서 활약합니다.",
    moderateHigh: "리더십을 발휘하며 팀원들에게 격려와 지원을 아끼지 않으며, 조직 내에서 중요한 역할을 합니다.",
    moderate: "필요할 때 리더 역할을 맡으며, 주로 팀의 조화를 유지하는 데 중점을 둡니다.",
    low: "리더로 나서기보다는 팀의 분위기를 좋게 만들고, 다른 사람들을 지원하는 역할을 선호합니다.",
    veryLow: "리더 역할보다는 팀 내에서 사람들을 돕고 지원하는 역할에 더 집중합니다."
  },
  ISFJ: {
    high: "당신은 타인을 돌보고 조직 내에서의 안정성을 중시하며, 조용하지만 강한 리더십을 발휘합니다.",
    moderateHigh: "리더로서의 책임감을 강하게 느끼며, 팀원들을 돌보는 데 탁월한 능력을 보입니다.",
    moderate: "필요할 때 리더 역할을 맡지만, 주로 팀의 안정성을 유지하는 데 중점을 둡니다.",
    low: "리더보다는 팀원으로서의 기여에 집중하며, 다른 사람들의 의견을 따르는 편입니다.",
    veryLow: "리더로 나서기보다는 팀 내에서 주어진 역할을 충실히 이행하는 것을 선호합니다."
  },
  ESTJ: {
    high: "당신은 체계적이고 조직적인 리더로서 팀을 이끌며, 확고한 결단력과 책임감을 바탕으로 중요한 결정을 내립니다.",
    moderateHigh: "리더로서의 역할을 자주 맡으며, 조직을 이끌고 목표를 달성하는 데 있어 탁월한 능력을 발휘합니다.",
    moderate: "필요할 때 리더 역할을 맡으며, 주로 체계적인 접근을 통해 팀을 이끕니다.",
    low: "리더보다는 팀의 일원으로서 자신의 역할에 집중하는 것을 선호합니다.",
    veryLow: "리더로 나서기보다는 다른 사람들의 결정을 따르며, 자신의 역할을 충실히 이행합니다."
  },
  INTP: {
    high: "당신은 논리적인 사고와 분석 능력을 바탕으로 팀을 이끌며, 문제 해결에 뛰어난 리더십을 발휘합니다.",
    moderateHigh: "리더로서의 역할을 맡아 팀을 이끌며, 논리적 사고를 바탕으로 결정을 내립니다.",
    moderate: "필요할 때 리더 역할을 맡지만, 주로 분석가로서 팀에 기여하는 것을 선호합니다.",
    low: "리더 역할보다는 팀의 문제 해결에 집중하며, 다른 사람들의 의견을 따르는 경향이 있습니다.",
    veryLow: "리더로 나서기보다는 자신의 역할에 집중하며, 주로 다른 사람들의 결정을 따릅니다."
  },
  ESFP: {
    high: "당신은 긍정적인 에너지와 유연한 사고로 팀을 이끌며, 분위기를 밝게 유지하는 리더십을 발휘합니다.",
    moderateHigh: "팀을 이끄는 데 있어 매우 유연하고, 팀원들의 사기를 높이는 데 탁월한 능력을 보입니다.",
    moderate: "필요할  때 리더 역할을 맡지만, 주로 팀의 분위기를 유지하는 데 중점을 둡니다.",
    low: "리더보다는 팀의 일원으로서 기여하는 것을 선호합니다.",
    veryLow: "리더로 나서기보다는 팀의 분위기를 유지하는 역할을 맡으며, 다른 사람들을 지원합니다."
  },
  INTJ: {
    high: "당신은 전략적 사고를 바탕으로 팀을 이끌며, 장기적인 계획을 세우고 목표를 달성하는 데 탁월한 리더십을 발휘합니다.",
    moderateHigh: "리더로서의 역할을 맡아 전략적으로 팀을 이끌며, 목표를 성취하는 데 주력합니다.",
    moderate: "필요할 때 리더 역할을 맡지만, 주로 계획 수립과 전략에 중점을 둡니다.",
    low: "리더 역할보다는 계획을 수립하고 팀을 지원하는 역할을 선호합니다.",
    veryLow: "리더로 나서기보다는 자신의 계획과 전략을 따르는 데 중점을 둡니다."
  },
  ENFJ: {
    high: "당신은 타인에 대한 배려와 사회적 책임감을 바탕으로 팀을 이끌며, 팀원들에게 영감을 주는 리더로서 활약합니다.",
    moderateHigh: "리더로서의 역할을 맡아 팀원들에게 동기부여를 하고, 그들의 발전을 돕는 데 탁월한 능력을 보입니다.",
    moderate: "필요할 때 리더 역할을 맡지만, 주로 팀원들과 협력하며 그들의 성장을 돕습니다.",
    low: "리더로 나서기보다는 팀의 분위기를 좋게 만들고, 다른 사람들을 지원하는 역할을 선호합니다.",
    veryLow: "리더 역할보다는 팀의 일원으로서 타인을 돕는 데 중점을 둡니다."
  },
  ISTP: {
    high: "당신은 빠른 상황 파악과 실용적인 사고로 리더십을 발휘하며, 위기 상황에서도 침착하게 문제를 해결합니다.",
    moderateHigh: "실용적인 사고를 바탕으로 팀을 이끌며, 필요할 때 빠르게 결정을 내리고 주도적으로 행동합니다.",
    moderate: "상황에 따라 리더 역할을 맡을 수 있으며, 실질적인 문제 해결을 중시하는 경향이 있습니다.",
    low: "리더로서의 역할을 맡기보다는 팀의 일원으로서 실용적인 기여에 더 집중합니다.",
    veryLow: "리더 역할을 꺼립니다. 대신 팀의 실질적인 문제 해결을 지원하는 데 주력합니다."
  },
};

export default leadershipMessages;
