import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import questions from "../data/questions";

function Question() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const navigate = useNavigate();

  const handleAnswer = (
    types,
    weights,
    survivalRate,
    riskTaking,
    morality,
    situationalAwareness,
    leadership,
    decisiveness,
    resilience
  ) => {
    // 선택한 답변을 저장
    setAnswers([
      ...answers,
      {
        types,
        weights,
        survivalRate,
        riskTaking,
        morality,
        situationalAwareness,
        leadership,
        decisiveness,
        resilience,
      },
    ]);

    if (currentQuestion + 1 < questions.length) {
      // 다음 질문으로 이동
      setCurrentQuestion(currentQuestion + 1);
    } else {
      // 결과를 계산하여 navigate로 넘김
      const {
        mbtiType,
        totalSurvivalRate,
        totalRiskTaking,
        totalMorality,
        totalSituationalAwareness,
        totalLeadership,
        totalDecisiveness,
        totalResilience,
      } = calculateResult();

      navigate("/result", {
        state: {
          mbtiType,
          survivalRate: totalSurvivalRate,
          riskTaking: totalRiskTaking,
          morality: totalMorality,
          situationalAwareness: totalSituationalAwareness,
          leadership: totalLeadership,
          decisiveness: totalDecisiveness,
          resilience: totalResilience,
          answers, // 선택한 답변도 넘김
        },
      });
    }
  };

  // MBTI와 각종 수치들 계산
  const calculateResult = () => {
    const counts = { E: 0, I: 0, S: 0, N: 0, T: 0, F: 0, J: 0, P: 0 };
    let totalSurvivalRate = 0;
    let totalRiskTaking = 0;
    let totalMorality = 0;
    let totalSituationalAwareness = 0;
    let totalLeadership = 0;
    let totalDecisiveness = 0;
    let totalResilience = 0;

    answers.forEach(
      ({
        types,
        weights,
        survivalRate,
        riskTaking,
        morality,
        situationalAwareness,
        leadership,
        decisiveness,
        resilience,
      }) => {
        // 여러 타입과 가중치를 각각 적용
        types.forEach((type, index) => {
          counts[type] += weights[index]; // 해당 타입에 가중치 적용
        });

        totalSurvivalRate += survivalRate;
        totalRiskTaking += riskTaking;
        totalMorality += morality;
        totalSituationalAwareness += situationalAwareness;
        totalLeadership += leadership;
        totalDecisiveness += decisiveness;
        totalResilience += resilience;
      }
    );

    const mbtiType =
      (counts.E > counts.I ? "E" : "I") +
      (counts.S > counts.N ? "S" : "N") +
      (counts.T > counts.F ? "T" : "F") +
      (counts.J > counts.P ? "J" : "P");

    return {
      mbtiType,
      totalSurvivalRate,
      totalRiskTaking,
      totalMorality,
      totalSituationalAwareness,
      totalLeadership,
      totalDecisiveness,
      totalResilience,
    };
  };

  return (
    <div style={styles.container}>
      <div style={styles.infoContainer}>
        <h2># {currentQuestion + 1}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: questions[currentQuestion].question,
          }}
        />

        {/* 애드센스 승인을 위한 안내 문구 */}
        <p
          style={{
            maxWidth: "800px",
            fontSize: "0.8rem",
            color: "#888",
            textAlign: "center",
            display: "none",
          }}
        >
          이 웹사이트는 사용자가 좀비 아포칼립스 시나리오에서 다양한 상황을
          해결하는 능력을 평가하기 위한 테스트를 제공합니다. 이 테스트는 교육적,
          엔터테인먼트적 목적으로 제작되었으며, 각 질문에 대한 답변은 사용자의
          성격 특성과 의사결정 능력에 대한 결과로 이어집니다. 실제 성격 검사
          결과는 질문을 완료한 후에 제공되며, 사용자가 선택한 답변에 따라
          생존률, 위험 감수 성향, 도덕적 판단, 상황 인식, 리더십, 결단력 및
          회복력에 대한 종합적인 평가가 이루어집니다. 테스트는 사용자의 자기
          인식과 리더십, 도덕적 판단 능력 등을 더욱 깊이 이해할 수 있는 기회를
          제공합니다.
        </p>

        <div>
          {questions[currentQuestion].options.map((option, index) => (
            <button
              key={index}
              style={styles.button}
              onClick={() =>
                handleAnswer(
                  option.type,
                  option.weight, // 가중치 배열로 전달
                  option.survivalRate,
                  option.riskTaking,
                  option.morality,
                  option.situationalAwareness,
                  option.leadership,
                  option.decisiveness,
                  option.resilience
                )
              }
            >
              {option.answer}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#282c34",
    color: "#ffffff",
    textAlign: "center",
  },
  infoContainer: {
    width: "95%",
    maxWidth: "900px",
    lineHeight: "1.5",
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    fontWeight: "bold",
    backgroundColor: "#61dafb",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "10px",
  },
};

export default Question;
