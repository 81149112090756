const results = {
  ISTJ: {
    title: "고독한 생존가",
    description:
      "당신은 철저한 계획과 주의 깊은 관찰력으로 위기를 대비하는 성향을 가진 생존가입니다. 빠르게 판단하고 자신만의 방식으로 침착하게 행동하며, 다른 사람들과의 협력보다는 홀로 상황을 컨트롤하려는 경향이 있습니다. 안전이 확신될 때까지 주변을 신중하게 탐색하고 생존을 위해 모든 것을 체계화하는 당신은 실수 없이 철저히 준비된 생존 전략을 갖춘 사람입니다.",
    survivalStyle:
      "상황을 철저히 분석하고 체계적으로 생존 계획을 세우는 스타일. 위기 속에서도 흔들리지 않고 일관성 있게 행동하며, 자신이 책임질 수 있는 부분을 철저히 관리합니다.",
    strengths: 
      "분석적 사고, 신중함, 철저한 준비성, 끈기와 인내. 고요한 환경에서도 전략적 접근으로 자신의 위치를 강화하며, 자원 관리에도 뛰어납니다.",
    weaknesses:
      "융통성이 부족할 수 있음, 고립될 가능성, 변화를 수용하는 데 어려움. 타인의 감정을 잘 이해하지 못해 협력하는 데 어려움을 겪을 수 있습니다.",
    recommendedStrategy:
      "협력할 수 있는 부분을 찾고, 주변의 도움을 받아보세요. 예상치 못한 변수에 유연하게 대처하는 능력을 키우면 더욱 생존에 유리할 것입니다. 때때로 자신에게 맞지 않는 상황에서도 열린 태도를 가져보세요.",
  },
  ISFJ: {
    title: "헌신적인 보호자",
    description:
      "위기 상황에서도 사람들을 챙기고자 하는 강한 의지로 보호자가 된 당신은, 다른 이들을 돌보는 일에 헌신적입니다. 위험한 상황 속에서도 주변 사람들을 우선시하고, 모두의 생존을 위해 따뜻한 지원을 아끼지 않으며, 안전한 환경을 만들어가고자 합니다. 은신처의 평화를 유지하기 위해 애쓰고, 도움이 필요한 사람들에게도 관심을 기울이는 모습을 보입니다.",
    survivalStyle:
      "주변 사람들의 안전을 우선하며 조용히 헌신하는 스타일. 사람들에게 정서적 안정감을 주고, 필요한 순간에 주도적으로 행동합니다.",
    strengths: 
      "배려심, 책임감, 신뢰성, 타인을 돌보는 능력. 신속한 대처보다는 차근차근 문제를 해결해가는 꾸준한 태도가 강점입니다.",
    weaknesses:
      "타인에게 지나치게 의존하게 되는 경향, 자기 자신을 돌보지 못할 수 있음. 스트레스를 감당하는 데 한계를 느끼거나, 혼자서 모든 걸 책임지려는 부담감을 가질 수 있습니다.",
    recommendedStrategy:
      "자신의 안전도 중요하니, 필요한 때에 스스로를 보호하는 방법을 익히세요. 자신의 한계를 인식하고, 개인적인 여유와 휴식이 필요할 때도 과감하게 취하세요. 주변 사람들에게 너무 의존하지 않도록 주의하세요.",
  },
  INFJ: {
    title: "비전을 가진 인도자",
    description:
      "당신은 모두의 생존을 위해 장기적인 생존 계획과 희망적인 비전을 제시하는 인도자입니다. 감정적으로 깊이 교감하며 주변 사람들에게 용기를 불어넣고, 조용하면서도 신념 있는 방식으로 위기 속에서도 긍정적인 방향을 제시하려고 합니다. 사람들을 격려하며 진정한 신뢰를 이끌어내는 리더십을 보여줍니다.",
    survivalStyle: 
      "장기적인 생존 목표와 방향성을 가지고 생존을 계획. 팀의 정신적 지주로서 모두의 생존을 위해 큰 그림을 그립니다.",
    strengths: 
      "통찰력, 헌신적 리더십, 동정심, 깊은 공감 능력. 혼란 속에서도 희망을 잃지 않고, 멀리 내다보는 능력으로 팀의 사기를 유지합니다.",
    weaknesses: 
      "현실적인 문제에 약할 수 있음, 과도하게 이상적일 수 있음. 실질적인 생존 문제를 놓칠 수 있으며, 이상만 추구하다 실천이 늦어질 수 있습니다.",
    recommendedStrategy: 
      "실질적인 생존 기술을 보강하고, 상황에 맞게 비전을 수정해보세요. 때로는 현실에 기반한 빠른 결정이 필요할 수 있습니다. 이상과 현실 사이의 균형을 찾는 것이 중요합니다.",
  },
  INTJ: {
    title: "냉철한 전략가",
    description:
      "위기 상황에서 빠르게 전략을 세우고 해결책을 찾는 데 집중하는 당신은, 치밀한 계획과 분석을 통해 생존 확률을 높이는 전략가입니다. 좀비 사태의 원인이나 해결 방안을 분석하는 데 관심을 가지며, 때로는 혼자서도 탁월한 생존 능력을 발휘합니다. 합리적이고 독립적인 사고로 모든 상황을 주도하며, 철저히 계산된 선택을 통해 생존 확률을 극대화합니다.",
    survivalStyle: 
      "위험 요소를 분석하고 효과적인 생존 전략을 설계. 모든 가능성을 검토하고, 리스크를 최소화하기 위한 냉정한 판단을 내립니다.",
    strengths: 
      "논리적 사고, 독립성, 장기적인 계획, 전략적 사고력. 비판적 사고를 바탕으로 실수를 줄이며, 모든 상황에 대비할 준비를 갖춥니다.",
    weaknesses: 
      "유연성 부족, 사회적 관계에 무관심할 수 있음, 지나친 통제 욕구. 타인과 협력하는 데 어려움을 느끼고, 감정적 상황에 서투를 수 있습니다.",
    recommendedStrategy: 
      "필요할 때는 즉흥적으로 대처하고 팀워크도 고려해보세요. 지나친 통제보다는 협력적 리더십을 발휘해 보세요. 인간관계를 이해하고, 그 속에서 나오는 시너지를 활용하는 것도 중요합니다.",
  },
  ISTP: {
    title: "창의적인 해결사",
    description:
      "위험한 상황에서도 냉정함을 유지하며 즉각적인 대처를 할 줄 아는 해결사인 당신은, 문제 해결에 있어 창의적이고 실질적인 방법을 찾습니다. 상황에 맞게 무기를 제작하거나 도구를 활용해 방어를 준비하며, 위험이 닥쳐도 유연하고 침착하게 대처할 수 있는 능력을 가지고 있습니다.",
    survivalStyle: 
      "창의적인 방법으로 문제를 해결하며 상황에 맞게 즉각적으로 대응. 예기치 않은 상황에서도 빠르게 문제를 분석하고 실질적인 해결책을 찾아냅니다.",
    strengths: 
      "기민한 반응, 실용적 사고, 독립성, 빠른 문제 해결 능력. 급변하는 상황에서도 자신감을 잃지 않고 침착하게 대처할 수 있는 능력을 발휘합니다.",
    weaknesses: 
      "장기적인 계획에 약할 수 있음, 때로는 지나치게 혼자 해결하려 함. 협력이 필요한 상황에서도 독립성을 고집하며, 팀워크가 부족할 수 있습니다.",
    recommendedStrategy: 
      "단기적인 문제 해결뿐 아니라 장기적인 생존 계획도 고려해 보세요. 다른 사람의 도움을 받아 더 효율적인 해결책을 찾을 수 있습니다. 팀 내에서의 역할을 고려해보세요.",
  },
  ISFP: {
    title: "조용한 평화주의자",
    description:
      "당신은 위기 상황 속에서도 평화롭고 조용하게 자신만의 방식으로 생존을 이어가는 평화주의자입니다. 다른 사람들을 돕기 위해 최선을 다하면서도, 자신만의 공간에서 생존을 위한 조용한 계획을 세우는 성향을 보입니다. 도전을 피하면서도 위기 속에서 자신과 타인의 안전을 존중하는 섬세함을 지니고 있습니다.",
    survivalStyle: 
      "갈등을 피하고 조용히 생존하며 주변 사람들과 평화롭게 협력. 상황이 불안정해도 내면의 평온을 유지하며 타인과의 조화를 중요시합니다.",
    strengths: 
      "감수성, 타인을 돕는 성향, 평화로운 태도, 섬세함. 위기 속에서도 침착하게 행동하고, 타인의 필요에 주의를 기울이는 능력을 발휘합니다.",
    weaknesses: 
      "주도적인 역할을 피할 수 있음, 위험을 직면하기 어려울 수 있음. 때로는 지나치게 소극적이어서 위기 상황에서 결정적인 순간을 놓칠 수 있습니다.",
    recommendedStrategy: 
      "위기 상황에서 자신만의 견해를 내세워 보세요. 적극적으로 리더십을 발휘할 수 있는 기회를 만들어보는 것도 좋습니다. 다른 사람의 기대에만 의존하지 말고, 자신을 위한 결단력을 키우세요.",
  },
  INFP: {
    title: "이상적인 방랑자",
    description:
      "위기 상황에서도 자신만의 이상을 지키며 생존을 모색하는 당신은, 공동체의 가치를 중요시하고 모든 사람의 안전을 고민하는 이상적인 방랑자입니다. 위험이 닥쳐도 사람들 간의 신뢰와 유대감을 중시하며, 자신이 믿는 가치와 신념을 기반으로 행동합니다. 위험 속에서도 주변 사람들의 생존을 함께 고민하며 이상적인 방향을 모색하는 특징을 가지고 있습니다.",
    survivalStyle: 
      "이상적인 방향을 추구하며 신념을 바탕으로 생존. 공동체의 윤리적 기준을 높이면서도, 개인의 내면적 신념을 지키는 태도를 보입니다.",
    strengths: 
      "강한 신념, 배려심, 이상주의, 사람들 간의 유대감 중시. 상황이 어려워도 타인을 먼저 생각하며, 신뢰 관계를 쌓아가는 데 능숙합니다.",
    weaknesses: 
      "현실적인 문제에 적응하기 어려울 수 있음, 너무 많은 고민으로 결정이 느려질 수 있음. 실질적인 문제 해결보다는 이상을 추구하다가 중요한 결정을 놓칠 수 있습니다.",
    recommendedStrategy: 
      "현실적인 문제 해결 방식을 배우고, 타인의 의견도 수용해 보세요. 감정적인 결정을 넘어 신속한 행동이 필요할 수 있습니다. 이상을 지키되 현실과 타협하는 법을 배워보세요.",
  },
  INTP: {
    title: "논리적인 탐구자",
    description:
      "상황을 논리적으로 분석하고 문제 해결을 위해 연구하는 탐구자인 당신은, 위기 속에서도 객관적이고 차분하게 문제에 접근합니다. 좀비 사태의 원인과 해결 방법을 연구하는 데 집중하며, 누구보다도 합리적인 방법으로 생존 전략을 세우려는 경향이 있습니다. 실험적이고 창의적인 방법을 통해 문제를 해결하려는 성향을 지녔습니다.",
    survivalStyle: 
      "상황을 분석하고 해결책을 연구하며 실험적인 접근으로 생존. 객관적이고 과학적인 방식으로 문제를 해결하며, 실험적인 아이디어를 제시합니다.",
    strengths: 
      "논리적 사고, 창의적 해결 방법, 객관성, 지적 호기심. 위기 속에서도 감정에 휘둘리지 않고 문제를 체계적으로 해결해나가는 능력을 가지고 있습니다.",
    weaknesses: 
      "감정적 반응에 서툴 수 있음, 팀워크에 어려움을 겪을 수 있음. 지나치게 이성적인 태도가 감정적으로 연결된 팀원들에게서 거부감을 일으킬 수 있습니다.",
    recommendedStrategy: 
      "상황에 따라 감정적 유연성을 발휘해 주변과 협력하세요. 함께 일하는 것이 때로는 더 나은 해결책을 제공할 수 있습니다. 감정적으로 교류하며 팀워크를 강화하는 방법도 익혀보세요.",
  },
  ESTP: {
    title: "무모한 모험가",
    description:
      "위험을 두려워하지 않고 직접 뛰어드는 모험가인 당신은, 생존을 위해 기꺼이 좀비와 맞서 싸우며 자원을 확보하려는 경향이 있습니다. 급변하는 상황에서도 신속하게 움직이며, 실질적인 문제 해결 능력으로 다른 생존자들에게 도움을 주는 강한 리더십을 발휘할 수 있습니다. 모험심과 빠른 판단력이 돋보입니다.",
    survivalStyle: 
      "즉각적인 대처로 위험을 피하며 자원을 빠르게 확보. 실질적인 해결책을 우선시하며 위험한 상황에서도 두려워하지 않고 전진합니다.",
    strengths: 
      "모험심, 신속한 판단력, 실질적 해결력, 뛰어난 적응력. 상황이 변해도 빠르게 대응하며 문제를 해결해 나가는 데 뛰어납니다.",
    weaknesses: 
      "계획 없이 행동할 수 있음, 위험을 과소평가할 수 있음. 때로는 지나친 자신감으로 인해 위험한 상황에 빠질 가능성이 있습니다.",
    recommendedStrategy: 
      "위험을 감수하되, 미리 계획을 세우고 행동해보세요. 행동하기 전에 잠시 멈추고 신중하게 생각해보는 것도 좋습니다. 장기적인 계획을 세워 자신을 보호하세요.",
  },
  ESFP: {
    title: "생기 넘치는 낙천가",
    description:
      "당신은 위기 상황에서도 긍정적이고 낙천적인 성격으로 주변 사람들에게 활기를 불어넣는 낙천가입니다. 생존자들에게 힘을 주고, 불안한 상황에서도 웃음을 잃지 않으며 공동체를 격려합니다. 모두의 사기를 높이는 역할을 하며, 생존 과정에서도 긍정적인 분위기를 주도합니다.",
    survivalStyle: 
      "긍정적인 에너지로 주변을 독려하며 공동체의 사기를 높임. 타인에게 활기를 불어넣으며, 자신도 긍정적인 태도를 유지합니다.",
    strengths: 
      "낙천성, 대인 관계 능력, 유연성, 분위기를 밝게 만드는 능력. 위기 상황에서도 흔들리지 않고 팀을 이끌어가는 긍정적인 힘을 발휘합니다.",
    weaknesses: 
      "가끔 현실적 판단이 부족할 수 있음, 지나친 낙관주의. 너무 긍정적인 태도가 상황을 과소평가하게 만들 수 있습니다.",
    recommendedStrategy: 
      "긍정적인 태도를 유지하되, 현실적인 필요를 점검하세요. 즐거움뿐 아니라 상황의 심각성도 함께 고려할 필요가 있습니다. 상황을 명확하게 인식하는 법을 배우세요.",
  },
  ENFP: {
    title: "열정적인 협력가",
    description:
      "위기 상황에서도 팀을 결집시키고 협력하는 데 주력하는 당신은, 생존자들을 독려하며 함께 위기를 극복하려는 열정적인 협력가입니다. 창의적인 해결책을 제시하며 모두의 의견을 경청하고, 사람들에게 긍정적인 힘을 전달하는 것을 즐깁니다. 혼란 속에서도 함께하는 힘을 믿으며 생존의 활로를 찾아갑니다.",
    survivalStyle: 
      "협력과 창의력으로 위기 상황에서 팀을 이끌며 생존. 팀 내의 다양한 의견을 듣고, 창의적인 해결책을 제시하며 결속을 다집니다.",
    strengths: 
      "창의력, 대인 관계, 유연한 사고, 협력 정신. 모든 팀원들의 의견을 존중하고, 혼란 속에서도 중심을 잃지 않는 능력을 발휘합니다.",
    weaknesses: 
      "산만해질 수 있음, 너무 많은 아이디어로 집중력을 잃을 수 있음. 아이디어는 풍부하지만, 실행에 옮기는 과정에서 어려움을 겪을 수 있습니다.",
    recommendedStrategy: 
      "목표를 유지하며, 현실적인 실행 방법에 집중하세요. 명확한 목표 설정과 계획이 필요합니다. 아이디어를 실행 가능한 계획으로 구체화하는 능력을 길러보세요.",
  },
  ENTP: {
    title: "혁신적인 도전자",
    description:
      "당신은 위기를 극복하기 위해 다양한 방법을 실험하고 새롭게 도전하는 혁신적인 도전자입니다. 전통적인 생존 방법에 얽매이지 않고 창의적인 방식으로 문제를 해결하며, 다소 무모해 보일 수 있지만 새로운 가능성을 탐구하는 데 적극적입니다. 생존을 위한 창의적인 아이디어를 제안하는 혁신적인 모습을 보여줍니다.",
    survivalStyle: 
      "창의적인 방법을 통해 도전을 두려워하지 않고 생존. 문제 해결에 있어 새로운 방식을 모색하고, 남들이 보지 못한 기회를 발견합니다.",
    strengths: 
      "창의력, 실험 정신, 문제 해결 능력, 다양한 가능성을 모색하는 능력. 급변하는 상황에서도 혁신적인 아이디어로 팀의 방향성을 제시합니다.",
    weaknesses: 
      "무모한 행동을 할 수 있음, 체계적인 계획 없이 행동할 수 있음. 때로는 지나치게 실험적이라 위험을 초래할 가능성이 있습니다.",
    recommendedStrategy: 
      "창의적인 아이디어를 실행할 때 위험을 고려하세요. 도전에 앞서 계획을 세우는 것이 중요합니다. 실험 정신과 함께 체계적인 계획을 수립하세요.",
  },
  ESTJ: {
    title: "위기를 통솔하는 지휘관",
    description:
      "위기 상황에서 사람들을 효율적으로 통솔하고 조직을 체계화하는 지휘관인 당신은, 단호한 결단력으로 생존자들을 이끌며 질서를 세웁니다. 역할을 분담해 조직을 운영하고 모두가 안전하게 생존할 수 있도록 체계적인 계획을 세우며, 혼란 속에서도 확고한 리더십을 발휘합니다.",
    survivalStyle: 
      "효율적으로 팀을 조직하며 체계적으로 생존을 이끌어감. 모든 역할을 명확히 분배하고, 조직적인 방식으로 문제를 해결합니다.",
    strengths: 
      "리더십, 조직력, 결단력, 체계적인 계획 수립. 팀을 이끄는 데 있어서 명확한 목표 설정과 분명한 실행 계획을 가지고 있습니다.",
    weaknesses: 
      "때로 융통성이 부족할 수 있음, 다른 의견을 무시할 수 있음. 지나치게 규율을 강조하여 팀 내의 유연성을 잃을 가능성이 있습니다.",
    recommendedStrategy: 
      "유연성을 발휘하고, 다양한 의견을 수용하세요. 다른 사람의 관점에서 문제를 바라보는 것도 중요합니다. 때로는 계획을 수정하고 현실적인 상황에 맞게 조정해보세요.",
  },
  ESFJ: {
    title: "다정한 지지자",
    description:
      "위기 상황에서도 사람들에게 다정하게 다가가고 그들을 돌보는 당신은, 공동체에서 모두를 안정시키는 지지자 역할을 합니다. 생존자들에게 신뢰와 유대감을 주고, 사람들 간의 갈등을 줄이며 모든 이들이 서로 의지할 수 있도록 배려하고 돕습니다.",
    survivalStyle: 
      "다정하고 협력적인 방식으로 주변의 안전을 도모. 다른 생존자들에게 감정적인 지원을 아끼지 않으며, 유대감을 강화하는 역할을 맡습니다.",
    strengths: 
      "친절함, 대인 관계 능력, 배려심, 타인과의 유대 강화 능력. 타인의 감정적 요구를 빠르게 파악하고, 안정감을 주는 존재로 자리잡습니다.",
    weaknesses: 
      "자신의 감정을 억누를 수 있음, 지나치게 타인을 의식할 수 있음. 자신이 감정적으로 지칠 위험이 있으며, 다른 사람을 지나치게 신경 쓰다가 자신의 필요를 놓칠 수 있습니다.",
    recommendedStrategy: 
      "자신의 필요도 챙기고 때로는 혼자만의 시간도 가져보세요. 자신을 돌보는 것도 중요한 생존 전략입니다. 자신의 한계를 인식하고, 감정적으로 회복할 시간을 가지세요.",
  },
  ENFJ: {
    title: "사기를 높이는 리더",
    description:
      "위기 상황에서도 사람들을 격려하고 생존의 비전을 제시하는 당신은, 생존자들에게 용기를 불어넣는 리더입니다. 주변의 생존자들에게 필요한 방향을 제시하고 상황을 적극적으로 개선하며, 힘든 상황 속에서도 희망을 전달합니다. 공동체의 정신적 지주로 자리하며, 모두가 함께 생존할 수 있도록 힘을 줍니다.",
    survivalStyle: 
      "팀을 격려하며 희망과 방향성을 제시. 다른 사람들에게 영감을 주고, 공감으로 팀을 이끌어갑니다.",
    strengths: 
      "리더십, 격려 능력, 신념, 사람들의 사기를 높이는 능력. 팀의 사기를 높이며, 자신감을 불어넣는 힘을 가지고 있습니다.",
    weaknesses: 
      "감정적으로 지칠 수 있음, 지나치게 희생할 수 있음. 자신을 돌보는 데 소홀해지기 쉽고, 다른 사람에게 의존하는 성향이 강할 수 있습니다.",
    recommendedStrategy: 
      "자신의 감정도 돌보며, 장기적인 비전을 유지하세요. 자기 관리도 중요한 요소입니다. 팀을 이끄는 동시에 자신의 필요를 인식하고, 감정적 회복 시간을 가지는 것도 중요합니다.",
  },
  ENTJ: {
    title: "철두철미한 총사령관",
    description:
      "당신은 생존을 위해 체계적인 계획을 세우고 필요한 자원을 효율적으로 배분하는 총사령관입니다. 생존의 모든 과정을 계획하고 예측하며, 장기적인 관점에서 문제를 해결하려는 철저한 준비성을 가지고 있습니다. 혼란스러운 상황에서도 전략적인 통솔력으로 그룹의 생존을 주도합니다.",
    survivalStyle: 
      "전략적으로 팀을 조직하고 장기적인 계획을 세움. 체계적인 계획을 바탕으로 생존을 주도하며, 장기적인 생존 목표를 설정합니다.",
    strengths: 
      "조직력, 논리적 사고, 전략적 사고, 자원 관리 능력. 팀 내에서 명확한 지시를 내리고, 자원을 효율적으로 분배하는 능력을 발휘합니다.",
    weaknesses: 
      "지나치게 통제하려 할 수 있음, 감정적 유연성 부족. 다른 사람의 의견을 받아들이는 데 어려움을 겪을 수 있고, 지나치게 독단적으로 행동할 수 있습니다.",
    recommendedStrategy: 
      "유연성을 발휘하고 다른 사람의 의견도 존중해 보세요. 감정적 연결도 중요한 생존 요소입니다. 타인의 의견을 수용하고, 자신이 놓친 부분을 보완해보세요.",
  },
};

export default results;
