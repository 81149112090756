const situationalAwarenessMessages = {
  ISTJ: {
    high: "현실적이고 조직적인 사고방식을 가진 당신은 주변 상황을 신속하게 파악하여 효과적으로 문제를 해결합니다.",
    moderateHigh:
      "상황을 잘 파악하며 필요한 순간에 신속하게 대처할 수 있는 능력을 갖추고 있습니다.",
    moderate:
      "상황을 대체로 잘 인식하지만, 때로는 세부 사항에 너무 집중하여 전체를 놓칠 수 있습니다.",
    low: "상황을 파악하는 데 시간이 걸리며, 갑작스러운 변화에 적응하는 데 어려움을 겪을 수 있습니다.",
    veryLow:
      "갑작스러운 상황 변화에 적절히 대처하지 못하고 압도될 수 있습니다.",
  },
  ISFJ: {
    high: "감각적이고 민감한 성향을 지닌 당신은 주변 사람들과 환경을 잘 이해하고, 신속하게 반응합니다.",
    moderateHigh:
      "주변 환경에 민감하게 반응하며 필요한 경우 적절히 대처할 수 있습니다.",
    moderate:
      "주변 상황을 대체로 잘 인식하지만, 때로는 감정적으로 반응할 가능성이 있습니다.",
    low: "상황 인식이 부족하여 빠르게 대처하지 못하거나, 변화에 적응하는 데 시간이 걸릴 수 있습니다.",
    veryLow:
      "예기치 못한 변화에 쉽게 당황할 수 있으며, 대처가 늦어질 수 있습니다.",
  },
  INFJ: {
    high: "전략적으로 사고하며, 주변 상황을 빠르게 분석하고 적절한 행동을 취하는 능력이 뛰어납니다.",
    moderateHigh:
      "주변 상황을 체계적으로 분석하며, 신속하게 결정을 내릴 수 있습니다.",
    moderate:
      "상황을 잘 파악하지만, 때로는 너무 계획적이거나 논리적으로 접근하여 결정을 지연시킬 수 있습니다.",
    low: "갑작스러운 변화에 적응하는 데 시간이 걸리며, 신속한 대처가 어려울 수 있습니다.",
    veryLow: "상황 변화를 신속히 인식하지 못해, 대응이 늦어질 수 있습니다.",
  },
  INTJ: {
    high: "직관적이고 창의적인 성향으로, 주변 상황을 민감하게 파악하며 신속하게 적응할 수 있습니다.",
    moderateHigh:
      "상황을 잘 이해하고, 필요할 때 창의적인 해결책을 제시할 수 있는 능력이 있습니다.",
    moderate:
      "상황을 대체로 잘 인식하지만, 때로는 이상적인 해결책을 찾느라 결정을 지연시킬 수 있습니다.",
    low: "상황 인식이 부족하여 즉각적인 대응이 어렵고, 갑작스러운 변화에 혼란을 겪을 수 있습니다.",
    veryLow: "변화에 신속하게 대처하지 못하고 당황할 가능성이 높습니다.",
  },
  ISTP: {
    high: "분석적이고 실용적인 사고로 주변 상황을 빠르게 이해하고, 필요한 대응을 신속하게 취합니다.",
    moderateHigh:
      "실용적이고 분석적으로 상황을 잘 파악하며, 필요한 순간에 신속히 대처합니다.",
    moderate:
      "상황을 대체로 잘 파악하지만, 너무 논리적으로 접근하여 결정을 미룰 수 있습니다.",
    low: "상황을 인식하는 데 다소 시간이 걸리며, 예상치 못한 변화에 신속하게 대처하기 어려울 수 있습니다.",
    veryLow: "상황 변화를 파악하지 못해 적절한 대응이 늦어질 수 있습니다.",
  },
  ISFP: {
    high: "감정적으로 민감한 성향을 지니고, 주변 상황과 사람들을 빠르게 파악하여 신속하게 대처합니다.",
    moderateHigh:
      "감정적으로 상황을 민감하게 느끼며 적절한 행동을 취할 수 있습니다.",
    moderate: "상황을 대체로 잘 인식하지만, 감정적으로 반응할 때가 있습니다.",
    low: "상황을 인식하는 데 시간이 걸리며, 갑작스러운 변화에 당황할 수 있습니다.",
    veryLow:
      "상황 인식이 부족하여 예상치 못한 상황에서 당황하거나 대처가 늦어질 수 있습니다.",
  },
  INFP: {
    high: "상황을 빠르게 분석하고, 그에 맞춰 신속하게 행동할 수 있는 능력을 가지고 있습니다.",
    moderateHigh:
      "상황을 잘 이해하며, 체계적으로 문제를 분석하고 대응할 수 있습니다.",
    moderate:
      "상황을 대체로 잘 인식하지만, 때로는 너무 많은 가능성을 고려하느라 결정을 지연시킬 수 있습니다.",
    low: "상황 인식이 부족하여 예상치 못한 상황에 대처하는 데 어려움을 겪을 수 있습니다.",
    veryLow:
      "갑작스러운 상황 변화에 적절히 대처하지 못하고 혼란을 겪을 수 있습니다.",
  },
  INTP: {
    high: "현실적이고 신속한 사고로 주변 상황을 빠르게 파악하고 적절하게 대응합니다.",
    moderateHigh:
      "상황을 빠르게 이해하고, 필요한 경우 즉각적인 행동을 취할 수 있습니다.",
    moderate: "상황을 대체로 잘 인식하지만, 즉흥적으로 반응할 때가 있습니다.",
    low: "상황 인식이 부족하여 즉각적인 대응이 어려울 수 있으며, 변화에 적응하는 데 시간이 걸릴 수 있습니다.",
    veryLow:
      "상황 인식이 부족하여 변화에 대처하는 데 어려움을 겪을 수 있습니다.",
  },
  ESTP: {
    high: "직관적이고 창의적인 성향으로 상황을 잘 파악하며, 빠르게 적응할 수 있습니다.",
    moderateHigh:
      "상황을 잘 파악하고, 창의적인 해결책을 제시하며 대처할 수 있는 능력이 있습니다.",
    moderate:
      "상황을 대체로 잘 이해하지만, 때로는 이상적인 해결책을 찾는 데 시간을 소요할 수 있습니다.",
    low: "상황 인식이 부족하여 갑작스러운 변화에 즉각적으로 반응하지 못할 수 있습니다.",
    veryLow: "변화에 신속하게 대응하지 못하고 당황할 가능성이 큽니다.",
  },
  ESFP: {
    high: "당신은 감각적이고 주변 상황을 신속하게 파악하여, 주변 환경에 적응하는 능력이 뛰어납니다.",
    moderateHigh:
      "감각적이고 유연하게 상황을 분석하며 필요한 행동을 신속히 취할 수 있습니다.",
    moderate:
      "상황을 대체로 잘 이해하지만, 지나치게 감각적으로 접근할 때가 있습니다.",
    low: "상황 인식이 다소 부족하며, 복잡한 상황에서는 결정을 지연시킬 수 있습니다.",
    veryLow:
      "상황을 신속하게 파악하지 못해 위험 상황에서 당황하거나 혼란스러워질 수 있습니다.",
  },
  ENFP: {
    high: "창의적이고 즉흥적인 사고로 주변 상황을 빠르게 파악하며, 적절한 대응책을 찾아낼 수 있습니다.",
    moderateHigh:
      "상황을 잘 파악하고, 독창적인 해결책을 빠르게 제시할 수 있습니다.",
    moderate:
      "상황을 대체로 잘 인식하지만, 때로는 지나치게 이상적인 대안을 찾는 데 시간을 소요할 수 있습니다.",
    low: "상황 인식이 부족하여 즉각적인 대응이 어려울 수 있습니다.",
    veryLow: "상황 변화를 신속히 인식하지 못해 혼란에 빠질 가능성이 큽니다.",
  },
  ENTP: {
    high: "논리적이고 현실적인 사고방식으로 주변 상황을 신속히 파악하고 대응하는 능력이 뛰어납니다.",
    moderateHigh:
      "상황을 분석하여 신속하게 결정을 내리고, 즉각적으로 대응할 수 있는 능력을 갖추고 있습니다.",
    moderate:
      "상황을 대체로 잘 인식하지만, 때로는 지나치게 논리적으로 접근하여 결정을 지연시킬 수 있습니다.",
    low: "상황 인식이 부족하여 신속한 대처가 어려울 수 있습니다.",
    veryLow: "예상치 못한 상황에서 대처가 늦어질 가능성이 큽니다.",
  },
  ESTJ: {
    high: "현실적이고 체계적인 사고로 주변 상황을 빠르게 파악하며, 신속하게 대응하는 능력을 갖추고 있습니다.",
    moderateHigh:
      "상황을 체계적으로 분석하며, 신속하게 적절한 결정을 내릴 수 있습니다.",
    moderate:
      "상황을 대체로 잘 인식하지만, 지나치게 현실적이고 조직적으로 접근하여 결정을 미룰 수 있습니다.",
    low: "상황 인식이 부족하여 예상치 못한 상황에 대처하는 데 어려움을 겪을 수 있습니다.",
    veryLow: "예상치 못한 상황에서 혼란스러워질 가능성이 큽니다.",
  },
  ESFJ: {
    high: "감정적으로 민감하며, 주변 상황을 잘 파악하고 타인을 돕는 데 뛰어난 능력을 발휘합니다.",
    moderateHigh:
      "주변 상황을 잘 파악하고 필요한 경우 타인을 돕는 데 빠르게 나설 수 있습니다.",
    moderate:
      "상황을 대체로 잘 인식하지만, 때로는 감정적인 반응으로 인해 결정을 지연시킬 수 있습니다.",
    low: "상황 인식이 부족하여 신속한 대처가 어려울 수 있으며, 감정적인 반응으로 혼란스러워질 수 있습니다.",
    veryLow:
      "상황 인식이 부족하여 예상치 못한 상황에서 대처가 늦어질 가능성이 큽니다.",
  },
  ENTJ: {
    high: "현실적이고 분석적인 성향을 가진 당신은 상황을 신속하게 파악하고 즉각적인 결정을 내리는 능력이 뛰어납니다.",
    moderateHigh:
      "상황을 체계적으로 분석하고, 신속하게 적절한 결정을 내릴 수 있습니다.",
    moderate:
      "상황을 대체로 잘 인식하지만, 때로는 지나치게 계획적으로 접근하여 결정을 미룰 수 있습니다.",
    low: "상황 인식이 부족하여 예상치 못한 상황에서 적절히 대처하기 어려울 수 있습니다.",
    veryLow: "상황 변화를 빠르게 인식하지 못해 대처가 늦어질 가능성이 큽니다.",
  },
  ENFJ: {
    high: "타인의 감정과 주변 환경을 민감하게 파악하며, 신속하게 적절한 행동을 취하는 능력이 뛰어납니다.",
    moderateHigh:
      "타인의 감정을 잘 이해하고, 상황을 빠르게 인식하여 필요한 경우 신속하게 대처할 수 있습니다.",
    moderate:
      "상황을 대체로 잘 인식하지만, 때로는 타인의 감정에 지나치게 집중하여 전체적인 상황을 놓칠 수 있습니다.",
    low: "상황을 파악하는 데 시간이 걸리며, 타인의 요구에 너무 집중하여 신속한 대처가 어려울 수 있습니다.",
    veryLow:
      "상황 변화를 신속하게 인식하지 못하고, 타인의 감정에 지나치게 영향을 받아 대처가 늦어질 수 있습니다.",
  },
};

export default situationalAwarenessMessages;
