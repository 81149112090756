const decisionMakingMessages = {
  ISTJ: {
    veryHigh:
      "당신은 철저한 분석과 계획을 통해 신중하고 빠르게 결정을 내립니다. 결정을 내린 후에도 흔들림 없이 밀고 나가는 성향을 보입니다.",
    high: "분석적이고 체계적인 접근을 통해 결정을 내리며, 실수를 최소화하려는 경향이 있습니다.",
    moderate:
      "결정을 내릴 때 논리적이지만, 과도한 분석으로 결정을 미루는 경향이 있을 수 있습니다.",
    low: "결정을 내리기 전에 많은 시간을 할애하며, 종종 결정을 내리기 어려워합니다.",
    veryLow:
      "결정을 내리는 데 있어 상당히 신중하며, 외부로부터의 지원이 필요할 때가 많습니다.",
  },
  ENFP: {
    veryHigh:
      "당신은 직관과 감정을 바탕으로 빠르고 유연하게 결정을 내리며, 새로운 가능성을 탐색하는 데 탁월합니다.",
    high: "결정을 내릴 때 직관적이며, 다양한 가능성을 고려하는 경향이 있습니다.",
    moderate:
      "결정 과정에서 주변의 의견을 듣고 협력하며, 상황에 따라 유연한 결정을 내립니다.",
    low: "결정을 내리는 데 시간이 걸리며, 종종 감정에 휘둘릴 수 있습니다.",
    veryLow:
      "결정을 내리기 어려워하며, 외부의 의견에 많이 의존하는 경향이 있습니다.",
  },
  ENTJ: {
    veryHigh:
      "당신은 결단력 있고 빠르게 결정을 내리며, 상황을 분석하고 실행으로 옮기는 능력이 매우 뛰어납니다.",
    high: "논리적이고 전략적으로 결정을 내리며, 실용적인 접근을 중요시합니다.",
    moderate:
      "결정을 내릴 때 신중하게 분석하며, 때로는 상황을 좀 더 고려할 필요가 있다고 느낍니다.",
    low: "결정 과정에서 많은 고민을 하며, 결정을 내리기 전에 추가적인 정보가 필요할 때가 있습니다.",
    veryLow:
      "결정을 내리는 데 시간이 걸리며, 종종 결정을 미루거나 다른 사람의 의견을 참고합니다.",
  },
  INFP: {
    veryHigh:
      "당신은 내면의 가치를 바탕으로 결정을 내리며, 타인의 감정과 도덕적 판단을 고려하는 결정을 내립니다.",
    high: "결정을 내릴 때 자신의 가치관과 감정에 충실하며, 주변 사람들에게 미치는 영향을 중요시합니다.",
    moderate:
      "결정을 내릴 때 자신의 감정과 도덕적 원칙을 따르며, 때로는 현실적인 측면을 고려하는 데 부족할 수 있습니다.",
    low: "결정을 내리기 전에 감정적 갈등을 겪을 수 있으며, 때로는 결정을 미루기도 합니다.",
    veryLow:
      "결정을 내리는 데 어려움을 느끼며, 종종 주변 사람들의 도움을 필요로 합니다.",
  },
  ESTP: {
    veryHigh:
      "당신은 상황에 따라 즉각적으로 결정을 내리며, 위험을 감수하면서도 결단력 있는 행동을 추구합니다.",
    high: "결정을 내리는 데 신속하고, 주저하지 않으며, 상황에 맞게 빠르게 행동합니다.",
    moderate:
      "결정을 내릴 때 신속하지만, 때로는 세부 사항을 놓치는 경우가 있을 수 있습니다.",
    low: "결정 과정에서 신중함이 부족할 수 있으며, 때로는 너무 즉흥적으로 결정을 내릴 때가 있습니다.",
    veryLow:
      "결정을 내리는 데 있어 다소 충동적이며, 가끔은 후회할 결정을 내릴 수 있습니다.",
  },
  ISFP: {
    veryHigh:
      "당신은 자신의 감정과 직관을 바탕으로 결정을 내리며, 타인의 감정을 중요하게 생각합니다.",
    high: "결정을 내릴 때 감정적 고려가 크며, 조화를 중시하는 결정을 내립니다.",
    moderate:
      "결정을 내릴 때 자신의 감정과 직관을 따르며, 때로는 결정을 미루는 경향이 있습니다.",
    low: "결정을 내리기 전에 감정적 갈등을 겪을 수 있으며, 결정을 내리는 데 어려움을 느낄 수 있습니다.",
    veryLow:
      "결정을 내리는 데 상당한 시간이 걸리며, 주로 타인의 의견을 따르는 경향이 있습니다.",
  },
  INFJ: {
    veryHigh:
      "당신은 직관과 통찰력을 바탕으로 결정을 내리며, 장기적인 관점을 중요시하는 경향이 있습니다.",
    high: "결정을 내릴 때 직관적이며, 자신의 내면의 가치를 바탕으로 결정을 내립니다.",
    moderate:
      "결정을 내릴 때 신중하며, 감정과 직관을 바탕으로 결정을 내리지만, 때로는 과도한 신중함을 보일 수 있습니다.",
    low: "결정을 내리기 전에 많은 고민을 하며, 결정을 미루는 경향이 있을 수 있습니다.",
    veryLow:
      "결정을 내리는 데 어려움을 겪으며, 주로 다른 사람들의 의견을 따르는 편입니다.",
  },
  ENTP: {
    veryHigh:
      "당신은 창의적인 사고와 빠른 판단력으로 결정을 내리며, 도전적인 상황에서 특히 강점을 발휘합니다.",
    high: "결정을 내리는 데 유연하고 창의적이며, 다양한 가능성을 고려한 결정을 내립니다.",
    moderate:
      "결정을 내릴 때 다양한 아이디어를 탐색하지만, 때로는 결정을 내리는 데 시간이 걸릴 수 있습니다.",
    low: "결정 과정에서 지나치게 많은 가능성을 고려하여 결정을 미루는 경향이 있을 수 있습니다.",
    veryLow:
      "결정을 내리는 데 많은 고민을 하며, 결정을 내리는 데 시간이 걸립니다.",
  },
  ESFJ: {
    veryHigh:
      "당신은 타인의 의견을 고려하여 신속하고 결단력 있게 결정을 내리며, 사람들의 의견을 조율하는 데 능숙합니다.",
    high: "결정을 내릴 때 타인의 의견을 존중하며, 모두가 만족할 수 있는 결정을 추구합니다.",
    moderate:
      "결정 과정에서 타인의 의견을 많이 고려하며, 때로는 결정을 미루는 경향이 있습니다.",
    low: "결정을 내리기 전에 주변 사람들의 의견을 기다리며, 종종 결정을 내리는 데 어려움을 겪습니다.",
    veryLow:
      "결정을 내리는 데 있어 주변 사람들의 영향을 많이 받으며, 주도적인 결정보다는 외부 의견을 따르는 편입니다.",
  },
  ISFJ: {
    veryHigh:
      "당신은 신중하게 상황을 분석하고 타인의 감정을 고려하여 결정을 내리며, 결정을 내리기 전에 충분한 시간을 투자합니다.",
    high: "결정을 내릴 때 타인의 감정과 상황을 신중하게 고려하며, 세부적인 부분까지 신경 씁니다.",
    moderate:
      "결정 과정에서 신중한 편이며, 종종 결정을 내리기 전에 많은 고민을 합니다.",
    low: "결정을 내리는 데 시간이 걸리며, 결정을 내리기 전에 타인의 의견을 많이 고려합니다.",
    veryLow:
      "결정을 내리는 데 어려움을 겪으며, 외부로부터의 지원이 필요할 때가 많습니다.",
  },
  ESTJ: {
    veryHigh:
      "당신은 논리적이고 체계적인 사고를 바탕으로 빠르고 정확하게 결정을 내리며, 결단력이 매우 강합니다.",
    high: "결정을 내리는 데 주저함이 없으며, 상황을 분석한 후 신속하게 결단을 내립니다.",
    moderate:
      "결정을 내릴 때 체계적으로 접근하며, 때로는 결정을 내리기 전에 추가적인 분석을 필요로 합니다.",
    low: "결정을 내리는 데 시간이 걸리며, 결정을 미루는 경향이 있을 수 있습니다.",
    veryLow:
      "결정을 내리는 데 신중함이 지나쳐, 결단을 내리기 전에 많은 고민을 할 수 있습니다.",
  },
  INTP: {
    veryHigh:
      "당신은 깊은 분석과 논리를 바탕으로 결정을 내리며, 문제 해결에 뛰어난 능력을 발휘합니다.",
    high: "결정을 내릴 때 논리적이고 분석적으로 접근하며, 실수를 최소화하려는 경향이 있습니다.",
    moderate:
      "결정 과정에서 많은 분석을 하지만, 때로는 지나친 분석으로 결정을 미루는 경향이 있을 수 있습니다.",
    low: "결정을 내리는 데 시간이 걸리며, 분석을 지나치게 중시하여 결정을 내리기 어려워할 때가 있습니다.",
    veryLow:
      "결정을 내리는 데 어려움을 겪으며, 외부로부터의 지원이 필요할 때가 많습니다.",
  },
  ESFP: {
    veryHigh:
      "당신은 직관적이고 감각적인 접근을 통해 신속하게 결정을 내리며, 실용적인 결단을 내리는 데 탁월합니다.",
    high: "결정을 내리는 데 주저하지 않으며, 상황에 맞게 빠르게 행동합니다.",
    moderate:
      "결정을 내릴 때 직관적이지만, 때로는 세부 사항을 놓칠 수 있습니다.",
    low: "결정 과정에서 지나치게 직관적일 수 있으며, 종종 세부적인 부분을 간과할 수 있습니다.",
    veryLow:
      "결정을 내리는 데 있어 다소 즉흥적이며, 가끔은 후회할 결정을 내릴 수 있습니다.",
  },
  INTJ: {
    veryHigh:
      "당신은 전략적 사고를 바탕으로 장기적인 목표를 고려하며, 결정을 내릴 때 신속하고 결단력이 있습니다.",
    high: "논리적이고 분석적으로 결정을 내리며, 장기적인 목표에 맞는 결정을 내립니다.",
    moderate:
      "결정을 내릴 때 논리적이고 체계적으로 접근하지만, 때로는 결정을 내리기 전에 많은 고민을 할 수 있습니다.",
    low: "결정을 내리는 데 신중하며, 결단을 내리기 전에 많은 고민을 할 수 있습니다.",
    veryLow:
      "결정을 내리는 데 어려움을 겪으며, 외부로부터의 지원이 필요할 때가 많습니다.",
  },
  ENFJ: {
    veryHigh:
      "당신은 타인의 감정과 상황을 고려하여 결정을 내리며, 다른 사람에게 영감을 주는 결정을 내리는 데 능숙합니다.",
    high: "결정을 내릴 때 타인의 감정을 고려하며, 그들의 발전을 도울 수 있는 결정을 내립니다.",
    moderate:
      "결정을 내릴 때 타인의 의견을 존중하지만, 때로는 자신의 가치관에 맞춰 결정을 내립니다.",
    low: "결정을 내리기 전에 타인의 의견에 지나치게 의존할 수 있습니다.",
    veryLow:
      "결정을 내리는 데 어려움을 겪으며, 외부로부터의 지원이 필요할 때가 많습니다.",
  },
  ISTP: {
    veryHigh:
      "당신은 문제를 신속하고 실용적으로 해결하며, 필요할 때 즉각적인 결정을 내리는 능력이 뛰어납니다.",
    high: "결정을 내릴 때 실용적이며, 주저하지 않고 즉각적인 행동을 취할 수 있습니다.",
    moderate:
      "결정을 내릴 때 신속하지만, 때로는 세부 사항을 간과할 수 있습니다.",
    low: "결정 과정에서 신속함이 부족할 수 있으며, 때로는 결정을 미루는 경향이 있을 수 있습니다.",
    veryLow:
      "결정을 내리는 데 어려움을 느낄 수 있으며, 주변의 지원이 필요할 때가 많습니다.",
  },
};

export default decisionMakingMessages;
