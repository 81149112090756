const mbtiBaseTraits = {
  ISTJ: { riskTaking: 5, morality: 10, survivalRate: 18 },   // 현실적이고 신중하지만 위험 감수 성향은 보통
  ISFJ: { riskTaking: -5, morality: 15, survivalRate: 12 },  // 배려 깊고 도덕적이지만 위험 회피 경향이 있음
  INFJ: { riskTaking: -10, morality: 20, survivalRate: -5 }, // 이상적이고 도덕성은 높지만 생존률 낮음
  INTJ: { riskTaking: 12, morality: 0, survivalRate: 18 },   // 계획적이고 논리적인 성향, 생존률 높음
  ISTP: { riskTaking: 15, morality: -5, survivalRate: 15 },  // 실용적이고 즉흥적 대응, 높은 생존률
  ISFP: { riskTaking: 2, morality: 14, survivalRate: -8 },   // 감정적이고 유연하지만 생존 상황에서는 취약
  INFP: { riskTaking: -8, morality: 20, survivalRate: -5 },  // 이상주의적이고 도덕적이지만 생존 상황에서 취약
  INTP: { riskTaking: 10, morality: 0, survivalRate: 0 },   // 논리적이며 분석적, 생존률 보통
  ESTP: { riskTaking: 18, morality: -10, survivalRate: 20 }, // 즉흥적이며 현실적 대응에 강함, 생존률 매우 높음
  ESFP: { riskTaking: 12, morality: 5, survivalRate: 16 },   // 감각적이고 활동적이지만 도덕성은 낮음
  ENFP: { riskTaking: 8, morality: 15, survivalRate: 0 },   // 창의적이고 도덕적이지만 생존률은 중간 정도
  ENTP: { riskTaking: 15, morality: -5, survivalRate: 10 },  // 논리적이며 도전적인 성향, 도덕성은 낮음
  ESTJ: { riskTaking: 10, morality: 0, survivalRate: 15 },   // 현실적이고 체계적이며 높은 생존률
  ESFJ: { riskTaking: -12, morality: 10, survivalRate: 10 },  // 감정적이고 배려 깊지만 위험 감수 성향은 낮음
  ENFJ: { riskTaking: 5, morality: 10, survivalRate: 0 },   // 도덕적이지만 생존 상황에서는 중간 정도
  ENTJ: { riskTaking: 15, morality: 5, survivalRate: 20 },   // 리더십과 전략적 사고 강함, 생존률 매우 높음
};

export default mbtiBaseTraits;
