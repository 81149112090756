const survivalMessages = {
  ISTJ: {
    high: "철저한 계획과 준비로 생존 가능성이 매우 높습니다. 위기 상황에서도 침착하게 대처하며 필요한 모든 준비를 미리 해두는 성향입니다.",
    moderateHigh:
      "대체로 생존 가능성이 높으며, 상황을 신중하게 분석하고 합리적으로 대처하는 성향을 보입니다.",
    moderate:
      "생존 가능성은 보통 수준이지만, 지나치게 보수적으로 판단할 경우 극단적인 상황에서 다소 취약할 수 있습니다.",
    low: "생존 가능성은 다소 낮으며, 갑작스러운 변화에 적응하는 데 어려움을 겪을 수 있습니다.",
    veryLow:
      "계획적이지만 예측 불가능한 상황에서는 결단력이 부족하여 생존 가능성이 매우 낮을 수 있습니다.",
  },
  ISFJ: {
    high: "타인을 돌보는 성향 덕분에 생존 가능성이 매우 높습니다. 공동체 내에서 중요한 역할을 맡으며, 안정적인 환경을 만들어 갑니다.",
    moderateHigh:
      "생존 가능성이 높으며, 위기 상황에서 팀을 결속시키고 보호하는 성향을 보입니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 지나치게 남을 먼저 챙기다 자신이 위험에 처할 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 돌봄과 배려가 지나쳐 상황 대처에 소극적일 수 있습니다.",
    veryLow:
      "타인을 돌보는 데 너무 집중하여 자신을 보호하지 못할 가능성이 있습니다.",
  },
  INFJ: {
    high: "비전과 통찰력을 바탕으로 생존 가능성이 매우 높습니다. 위기 상황에서 남들에게 영감을 주며 상황을 전략적으로 이끌어갑니다.",
    moderateHigh:
      "생존 가능성이 높으며, 신중하고 계획적인 대처로 위기를 잘 넘기는 성향입니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 지나치게 미래를 생각하다가 현재의 위기를 놓칠 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 내면의 갈등이 생존에 방해가 될 수 있습니다.",
    veryLow:
      "생존 가능성이 매우 낮으며, 감정적 혼란이 위기 상황에서 결단력을 저해할 수 있습니다.",
  },
  INTJ: {
    high: "전략적인 사고 덕분에 생존 가능성이 매우 높습니다. 철저한 계획과 분석으로 위기를 해결하는 데 탁월한 능력을 보입니다.",
    moderateHigh:
      "생존 가능성이 높으며, 논리적이고 신속한 판단으로 상황을 유리하게 이끌어갑니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 지나치게 고집스러운 전략이 상황을 어렵게 만들 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 변화에 유연하게 대처하지 못할 수 있습니다.",
    veryLow:
      "과도하게 논리에 의존해 감정적 요소를 무시하다가 생존에 어려움을 겪을 수 있습니다.",
  },
  ISTP: {
    high: "빠른 상황 판단과 유연한 대처 덕분에 생존 가능성이 매우 높습니다. 창의적인 해결책을 즉시 찾아내는 능력을 가집니다.",
    moderateHigh:
      "생존 가능성이 높으며, 신속하게 대응하면서도 실질적인 해결책을 찾는 경향이 있습니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 필요 이상의 위험을 감수하다가 위험에 처할 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 지나치게 즉흥적인 행동으로 위험에 빠질 가능성이 있습니다.",
    veryLow:
      "위험한 상황에서 즉흥적으로 대처하다가 생존 가능성이 매우 낮아질 수 있습니다.",
  },
  ISFP: {
    high: "위기 상황에서도 조용히 상황을 분석하고 적응하는 능력이 뛰어나 생존 가능성이 매우 높습니다.",
    moderateHigh:
      "생존 가능성이 높으며, 상황에 맞게 유연하게 대처하는 성향을 보입니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 지나치게 자신만의 방식을 고집하다가 위험할 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 타인을 지나치게 배려하다가 자신의 안전을 놓칠 수 있습니다.",
    veryLow: "도전적 상황에서 내향적인 성향이 생존을 어렵게 만들 수 있습니다.",
  },
  INFP: {
    high: "이상주의적이지만 생존에 대한 강한 의지를 바탕으로 위기 상황에서 빠르게 적응할 수 있습니다.",
    moderateHigh:
      "생존 가능성이 높으며, 도덕적 원칙을 지키면서도 상황에 따라 유연하게 대처하는 성향을 보입니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 이상을 지나치게 고집하다가 현실적 문제를 간과할 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 내적 갈등으로 인해 위기 대처가 어려워질 수 있습니다.",
    veryLow:
      "이상주의가 지나쳐 현실의 위협에 제대로 대응하지 못할 수 있습니다.",
  },
  INTP: {
    high: "논리적 사고와 분석적 성향 덕분에 생존 가능성이 매우 높습니다. 위기 상황에서도 빠르게 해결책을 찾는 능력이 탁월합니다.",
    moderateHigh:
      "생존 가능성이 높으며, 분석적으로 문제를 해결하면서 상황을 통제합니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 지나치게 이론에만 의존하다가 실질적 문제를 놓칠 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 현실적인 행동이 부족할 수 있습니다.",
    veryLow:
      "위기 상황에서 지나치게 이론적 접근만을 시도하다가 생존에 어려움을 겪을 수 있습니다.",
  },
  ESTP: {
    high: "즉각적인 대처 능력과 과감한 성향 덕분에 생존 가능성이 매우 높습니다. 위기 상황에서 신속하게 결정을 내리고 행동합니다.",
    moderateHigh:
      "생존 가능성이 높으며, 실질적인 해결책을 찾고 다른 사람들을 이끄는 성향이 있습니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 무모한 결정을 내릴 위험이 있습니다.",
    low: "생존 가능성이 다소 낮으며, 지나치게 즉흥적인 판단으로 위험을 초래할 수 있습니다.",
    veryLow:
      "위험을 무시하고 과감한 행동을 하다가 생존 가능성이 크게 낮아질 수 있습니다.",
  },
  ESFP: {
    high: "긍정적이고 적응력이 뛰어나 생존 가능성이 매우 높습니다. 위기 상황에서도 낙천적으로 대처하며 주변 사람들에게 힘을 줍니다.",
    moderateHigh:
      "생존 가능성이 높으며, 유연하게 대처하고 상황에 맞게 빠르게 적응하는 성향을 보입니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 지나치게 낙천적으로 상황을 간과할 위험이 있습니다.",
    low: "생존 가능성이 다소 낮으며, 문제를 너무 가볍게 여겨 위기 대처가 어려울 수 있습니다.",
    veryLow:
      "낙천적인 성향이 지나쳐 현실적 위협을 제대로 인식하지 못할 수 있습니다.",
  },
  ENFP: {
    high: "뛰어난 적응력과 빠른 상황 판단력 덕분에 생존 가능성이 매우 높습니다. 즉흥적인 성향이 위기 상황에서 빛을 발합니다.",
    moderateHigh:
      "생존 가능성이 높으며, 창의적인 해결책을 빠르게 제시하고 적응하는 성향을 보입니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 즉흥적인 행동이 문제를 초래할 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 감정적 판단으로 위기를 겪을 수 있습니다.",
    veryLow:
      "즉흥적이고 감정적인 행동이 지나쳐 생존 가능성이 매우 낮을 수 있습니다.",
  },
  ENTP: {
    high: "문제를 해결하는 혁신적인 방법을 찾는 성향 덕분에 생존 가능성이 매우 높습니다. 빠르게 아이디어를 제시하고 실천하는 경향이 있습니다.",
    moderateHigh:
      "생존 가능성이 높으며, 창의적으로 상황을 해결하는 능력이 뛰어납니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 지나치게 실험적인 접근으로 문제가 생길 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 너무 많은 가능성을 탐구하다가 실질적 행동을 놓칠 수 있습니다.",
    veryLow:
      "위기 상황에서 지나치게 실험적인 접근을 시도하다가 생존에 실패할 수 있습니다.",
  },
  ESTJ: {
    high: "효율적이고 논리적인 성향 덕분에 생존 가능성이 매우 높습니다. 위기 상황에서도 질서를 세우고 상황을 주도하는 능력이 탁월합니다.",
    moderateHigh:
      "생존 가능성이 높으며, 실용적이고 신속하게 문제를 해결하는 경향이 있습니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 지나치게 규칙적이거나 고집스러운 성향이 문제가 될 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 유연하지 못한 접근이 위험을 초래할 수 있습니다.",
    veryLow:
      "위기 상황에서 지나치게 통제하려다가 생존 가능성이 크게 떨어질 수 있습니다.",
  },
  ESFJ: {
    high: "타인을 돌보는 능력과 조직력을 바탕으로 생존 가능성이 매우 높습니다. 위기 상황에서 사람들을 안정시키고 안전한 환경을 만듭니다.",
    moderateHigh:
      "생존 가능성이 높으며, 타인을 돕고 상황을 잘 관리하는 성향을 보입니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 지나치게 타인을 돌보다 자신의 안전을 놓칠 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 다른 사람을 너무 챙기다가 위험에 처할 수 있습니다.",
    veryLow:
      "타인을 돕는 데 너무 집중하여 자신의 안전을 돌보지 못할 수 있습니다.",
  },
  ENFJ: {
    high: "강한 리더십과 대인관계 능력 덕분에 생존 가능성이 매우 높습니다. 다른 사람들을 이끌어가며 위기 상황을 해결하는 능력이 뛰어납니다.",
    moderateHigh:
      "생존 가능성이 높으며, 다른 사람들을 독려하고 문제를 해결하는 성향을 보입니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 타인을 너무 챙기다 자신의 안전을 놓칠 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 도덕적 고민이 생존에 방해가 될 수 있습니다.",
    veryLow:
      "타인을 지나치게 생각하다가 위기 상황에서 자신의 안전을 놓칠 수 있습니다.",
  },
  ENTJ: {
    high: "전략적 사고와 리더십 덕분에 생존 가능성이 매우 높습니다. 빠르게 결정을 내리고 상황을 주도하는 능력이 탁월합니다.",
    moderateHigh:
      "생존 가능성이 높으며, 상황을 빠르게 파악하고 계획을 세워 문제를 해결하는 성향을 보입니다.",
    moderate:
      "생존 가능성은 보통 수준이며, 지나치게 통제하려다가 문제가 생길 수 있습니다.",
    low: "생존 가능성이 다소 낮으며, 유연하지 못한 계획이 위험을 초래할 수 있습니다.",
    veryLow:
      "위기 상황에서 지나치게 자신을 과신하여 생존 가능성이 크게 떨어질 수 있습니다.",
  },
};

export default survivalMessages;
