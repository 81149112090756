const resilienceMessages = {
  ISTJ: {
    high: "어떠한 시련에도 쉽게 무너지지 않고, 차분하고 계획적인 대처를 통해 빠르게 회복할 수 있습니다.",
    moderateHigh:
      "어려운 상황에서도 흔들리지 않으며, 문제를 체계적으로 해결하고 회복하는 경향이 있습니다.",
    moderate:
      "회복력이 평균 수준으로, 일정한 시련에는 견딜 수 있지만 예상하지 못한 변화에는 다소 약할 수 있습니다.",
    low: "위기 상황에서 회복력이 낮으며, 힘든 상황이 지속되면 지치거나 의욕을 상실할 수 있습니다.",
    veryLow:
      "회복력이 매우 낮아, 어려움을 극복하는 데 많은 시간이 필요할 수 있으며, 지원이 필요할 때가 많습니다.",
  },
  ENFP: {
    high: "자신의 이상과 희망을 바탕으로 어려움을 극복하는 데 뛰어나며, 감정적으로도 빠르게 회복하는 성향을 보입니다.",
    moderateHigh:
      "힘든 상황에서도 낙관적인 태도로 잘 견디며, 새로운 시도와 기회를 통해 회복할 수 있습니다.",
    moderate:
      "어려움 속에서도 희망을 찾으려 하지만, 때로는 감정적으로 흔들릴 수 있습니다.",
    low: "감정적으로 민감한 편이며, 힘든 상황이 길어질수록 쉽게 지칠 수 있습니다.",
    veryLow:
      "감정적으로 매우 취약하여, 어려움을 극복하는 데 시간이 오래 걸리며, 주변의 지지가 필요할 수 있습니다.",
  },
  ENTJ: {
    high: "강한 의지와 목표 지향적인 성향 덕분에 어려움에도 쉽게 굴하지 않고 빠르게 회복하는 능력을 보입니다.",
    moderateHigh:
      "힘든 상황에서도 목표를 향해 나아가며, 전략적으로 회복하는 경향이 있습니다.",
    moderate:
      "회복력이 평균 수준으로, 문제를 해결하는 데는 능숙하지만 감정적인 피로에는 다소 약할 수 있습니다.",
    low: "목표에 집중하는 만큼 스트레스가 쌓일 수 있으며, 회복 속도가 느려질 수 있습니다.",
    veryLow:
      "회복력이 낮아, 큰 스트레스를 받을 경우 심리적으로 쉽게 지치고 회복하는 데 어려움을 겪을 수 있습니다.",
  },
  INFP: {
    high: "내면의 신념과 가치에 대한 확신이 어려움을 극복하는 데 큰 힘이 되며, 자신을 치유하는 능력이 매우 뛰어납니다.",
    moderateHigh:
      "내면의 가치를 통해 회복하며, 감정적인 위안을 찾을 수 있는 성향을 보입니다.",
    moderate:
      "어려운 상황에서도 자신을 돌아보며 회복하지만, 감정적으로 쉽게 상처받을 수 있습니다.",
    low: "감정적으로 취약하며, 지속적인 어려움에 쉽게 지칠 수 있습니다.",
    veryLow:
      "회복력이 매우 낮아, 감정적으로 매우 취약하며 주변의 도움이 절실히 필요할 수 있습니다.",
  },
  ESTP: {
    high: "문제 해결에 강한 자신감을 가지고 있으며, 위기 상황에서도 침착하게 대처하며 빠르게 회복합니다.",
    moderateHigh:
      "위기 상황에서도 침착하고 대담하게 대처하며, 회복력이 뛰어납니다.",
    moderate:
      "어려운 상황에서 어느 정도 견디지만, 때로는 회복이 더디게 이루어질 수 있습니다.",
    low: "회복력이 다소 낮으며, 지속적인 스트레스나 도전에 쉽게 피로를 느낄 수 있습니다.",
    veryLow:
      "스트레스를 잘 견디지 못하며, 회복하는 데 많은 시간이 필요할 수 있습니다.",
  },
  ISFP: {
    high: "감정적으로 깊은 회복력을 지니고 있으며, 자신의 내면을 돌아보면서 빠르게 회복할 수 있습니다.",
    moderateHigh:
      "내면의 힘을 통해 어려움을 극복하며, 회복 속도가 빠른 편입니다.",
    moderate:
      "어려운 상황에서 감정적으로 흔들리지만, 스스로를 치유할 수 있는 능력이 있습니다.",
    low: "감정적으로 취약하여, 회복하는 데 다소 시간이 걸릴 수 있습니다.",
    veryLow:
      "회복력이 매우 낮아, 스트레스를 극복하는 데 어려움을 느끼며, 주변의 도움이 필요할 수 있습니다.",
  },
  INFJ: {
    high: "내면의 통찰력과 신념을 바탕으로 회복하는 능력이 뛰어나며, 감정적으로 빠르게 회복하는 경향이 있습니다.",
    moderateHigh:
      "힘든 상황에서도 내면의 신념을 통해 회복하며, 감정적 위기를 잘 극복합니다.",
    moderate:
      "어려운 상황에서도 자신의 내면을 돌아보며 회복하지만, 때로는 감정적인 피로를 느낄 수 있습니다.",
    low: "감정적인 스트레스에 민감하며, 회복하는 데 시간이 다소 걸릴 수 있습니다.",
    veryLow:
      "회복력이 낮아, 감정적으로 쉽게 지치며 주변의 지지가 절실히 필요할 때가 많습니다.",
  },
  ENTP: {
    high: "어려운 상황에서도 새로운 아이디어와 가능성을 통해 빠르게 회복하며, 어려움을 극복하는 데 뛰어난 능력을 보입니다.",
    moderateHigh:
      "낙관적이고 창의적인 사고로 어려움을 이겨내며, 회복력이 강한 편입니다.",
    moderate:
      "어려운 상황에서 회복하려고 하지만, 종종 여러 가지 선택지 사이에서 갈등을 겪을 수 있습니다.",
    low: "회복력이 다소 낮으며, 스트레스가 쌓일 경우 쉽게 지칠 수 있습니다.",
    veryLow:
      "어려움을 극복하는 데 많은 시간이 필요하며, 종종 주변의 도움이 필요할 수 있습니다.",
  },
  ESFJ: {
    high: "타인과의 관계에서 힘을 얻으며, 어려운 상황에서도 빠르게 회복할 수 있는 능력이 뛰어납니다.",
    moderateHigh:
      "타인의 지지를 받으며 회복하는 데 강점이 있으며, 어려움 속에서도 잘 견뎌냅니다.",
    moderate:
      "회복력이 평균 수준으로, 타인과의 관계에서 힘을 얻지만 혼자서의 회복은 다소 더딜 수 있습니다.",
    low: "어려운 상황에서 쉽게 지칠 수 있으며, 타인의 도움이 필요할 수 있습니다.",
    veryLow:
      "회복력이 낮아, 스트레스가 쌓이면 혼자서 극복하는 데 어려움을 겪을 수 있습니다.",
  },
  ISFJ: {
    high: "어려운 상황에서도 묵묵히 견디며, 타인의 도움을 통해 빠르게 회복하는 능력이 있습니다.",
    moderateHigh:
      "타인의 지지와 도움을 받으며 회복할 수 있으며, 회복력이 강한 편입니다.",
    moderate:
      "어려운 상황에서 어느 정도 견디지만, 타인의 지지가 부족할 경우 쉽게 지칠 수 있습니다.",
    low: "회복력이 다소 낮으며, 지속적인 어려움에 쉽게 피로를 느낄 수 있습니다.",
    veryLow:
      "어려움을 극복하는 데 많은 시간이 필요하며, 타인의 도움 없이는 회복하기 어려울 수 있습니다.",
  },
  ESTJ: {
    high: "철저한 계획과 실행력을 바탕으로 빠르게 회복하며, 어려움 속에서도 굴하지 않는 성향을 보입니다.",
    moderateHigh:
      "어려운 상황에서도 체계적으로 문제를 해결하고 회복하는 능력이 뛰어납니다.",
    moderate:
      "회복력이 평균 수준으로, 어려움 속에서도 잘 견디지만, 감정적인 피로가 쌓일 수 있습니다.",
    low: "지속적인 스트레스에 쉽게 지칠 수 있으며, 회복하는 데 시간이 걸릴 수 있습니다.",
    veryLow:
      "회복력이 매우 낮아, 어려움을 극복하는 데 많은 시간이 필요할 수 있으며, 외부의 도움이 필요할 때가 많습니다.",
  },
  INTP: {
    high: "논리적 사고와 분석을 통해 어려운 상황을 빠르게 해결하며, 회복하는 데 뛰어난 능력을 보입니다.",
    moderateHigh:
      "문제를 분석하고 해결하는 능력 덕분에 빠르게 회복할 수 있는 성향을 보입니다.",
    moderate:
      "어려운 상황에서 회복하려고 노력하지만, 감정적인 스트레스에는 다소 취약할 수 있습니다.",
    low: "지속적인 스트레스에 민감하며, 회복하는 데 다소 시간이 걸릴 수 있습니다.",
    veryLow:
      "회복력이 낮아, 어려움을 극복하는 데 시간이 오래 걸리며, 주변의 지지가 필요할 수 있습니다.",
  },
  ESFP: {
    high: "즐거움과 활력을 통해 빠르게 회복하며, 어려운 상황에서도 긍정적인 에너지를 유지합니다.",
    moderateHigh:
      "힘든 상황에서도 긍정적인 태도를 유지하며, 회복력이 뛰어납니다.",
    moderate:
      "어려운 상황에서도 긍정적인 태도를 유지하려고 하지만, 지속적인 스트레스에는 취약할 수 있습니다.",
    low: "스트레스가 쌓일 경우 쉽게 지칠 수 있으며, 회복하는 데 시간이 걸릴 수 있습니다.",
    veryLow:
      "회복력이 매우 낮아, 스트레스를 극복하는 데 어려움을 느낄 수 있으며, 주변의 지지가 필요할 수 있습니다.",
  },
  ISTP: {
    high: "위기 상황에서도 침착하게 문제를 해결하며, 빠르게 회복하는 능력을 지니고 있습니다.",
    moderateHigh:
      "어려운 상황에서도 실용적이고 논리적인 사고를 바탕으로 빠르게 회복할 수 있습니다.",
    moderate:
      "일정한 어려움은 잘 견디지만, 감정적인 스트레스에는 다소 약할 수 있습니다.",
    low: "지속적인 스트레스나 도전에 쉽게 지칠 수 있으며, 회복하는 데 시간이 걸릴 수 있습니다.",
    veryLow:
      "회복력이 매우 낮아, 어려움을 극복하는 데 어려움을 느낄 수 있으며, 주변의 지지가 필요할 때가 많습니다.",
  },
  ENFJ: {
    high: "타인에 대한 강한 공감과 지도력을 바탕으로 회복력이 뛰어나며, 자신과 주변 사람들의 어려움을 빠르게 극복할 수 있습니다.",
    moderateHigh:
      "타인과의 유대감을 통해 회복하며, 어려운 상황에서도 낙관적인 태도를 유지할 수 있습니다.",
    moderate:
      "타인의 감정을 잘 이해하며 회복하려고 하지만, 자신을 돌보지 않으면 쉽게 지칠 수 있습니다.",
    low: "타인의 감정에 너무 집중하다 보면 자신의 회복력이 떨어질 수 있으며, 힘든 상황이 지속되면 쉽게 소진될 수 있습니다.",
    veryLow:
      "타인의 문제를 지나치게 신경 쓰는 경향이 있어, 자신의 회복이 느리거나 지원이 필요할 때가 많습니다.",
  },
  INTJ: {
    high: "논리적이고 계획적인 사고를 통해 어려움을 빠르게 극복하며, 회복하는 데 매우 뛰어난 능력을 보입니다.",
    moderateHigh:
      "힘든 상황에서도 체계적이고 전략적으로 문제를 해결하며, 회복하는 경향이 강합니다.",
    moderate:
      "일정한 회복력은 있지만, 예상치 못한 감정적인 스트레스에 다소 취약할 수 있습니다.",
    low: "계획에서 벗어나는 상황에서는 쉽게 지칠 수 있으며, 회복 속도가 느려질 수 있습니다.",
    veryLow:
      "예상치 못한 문제에 직면하면 회복력이 매우 낮아지고, 감정적인 지원이 필요할 수 있습니다.",
  },
};

export default resilienceMessages;
