import React from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const startTest = () => {
    navigate("/question");
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>좀비 아포칼립스에서 당신은?</h1>
      <p style={styles.description}>
        멸망해가는 세상에서 우리는 어떻게 행동해야 할까요? <br />
        성향에 따른 상황 대처 방법을 확인하고, <br />
        자신의 생존 가능성을 평가해 보세요.
      </p>
      <button style={styles.button} onClick={startTest}>
        테스트 시작하기
      </button>
      <p style={styles.sub}>
        2024-10-23 UPDATE<br></br>
        <span>
          문항 추가 및 각 문항의 밸런스 조정
        </span>
      </p>
      <p style={styles.footer}>
        이 테스트는 엔터테인먼트 목적으로 제공되며, <br />
        결과는 사용자의 선택에 따라 계산됩니다.
      </p>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#282c34",
    color: "#ffffff",
    textAlign: "center",
    width: "95%",
    maxWidth: "900px",
    boxsizing: "border-box",
    margin: "0 auto",
  },
  title: {
    fontSize: "2.5rem",
    marginBottom: "20px",
  },
  description: {
    fontSize: "1.2rem",
    marginBottom: "30px",
    lineHeight: "1.5",
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    fontWeight: "bold",
    backgroundColor: "#61dafb",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  footer: {
    marginTop: "20px",
    fontSize: "0.8rem",
    color: "#888",
    textAlign: "center",
  },
  sub: {
    fontSize: "0.8rem",
    color: "#ccc",
  },
};

export default Home;
